import NextSteps from '../NextSteps.vue'

export default [
    {
        path: '/next-steps/:fd_id',
        name: 'Next Steps',
        component: NextSteps,
        meta: {
            layout: 'public-layout'
        }
    },
]