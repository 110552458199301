<template>
    <v-container fluid style="padding:0px;min-height:1100px;">
        <v-row style="margin-top:10px;">
            <v-col cols="12" style="padding:0px;text-align:center;">
                <div class="image-container">
                    <v-img v-if="!isMobile()" :src="require('../../assets/desktop-next-steps.jpeg')" max-height="400" width="800" contain
                        style="box-shadow: #87A9AF 0px 0px 0px 5px;" />
                    <v-img v-else :src="require('../../assets/mobile-next-steps.jpeg')" max-height="400" width="350" contain
                        style="box-shadow: #87A9AF 0px 0px 0px 5px;" />
                    <v-btn @click="openSection(1)" v-if="section == null" text class="text-overlay pa-7"
                        id="main-content">
                        <u>Get Started</u>
                    </v-btn>
                    <!-- <label v-if="section != null" class="text-overlay pa-2">
                        <u v-if="section == 1">After Funeral Guide</u>
                        <u v-if="section == 2">Registration of Death</u>
                        <u v-if="section == 3">People / Organisations to Notify</u>
                        <u v-if="section == 4">Financial Matters</u>
                        <u v-if="section == 5">Legal Affairs</u>
                        <u v-if="section == 6">Bereavement Support</u>
                        <u v-if="section == 7">Explore Advice</u>
                    </label> -->
                </div>
            </v-col>
        </v-row>
        <v-row v-if="section == null" style="max-width:1200px;margin-left:auto;margin-right:auto;">
            <v-col :cols="isMobile() ? '6' : '4'" :class="isMobile() ? 'pa-5' : 'pa-10'" style="justify-items:center;">
                <v-card @click="openSection(1)" elevation="8"
                    style="width:300px;background-color:#91B460;min-height:210px;">
                    <v-card-title class="justify-center text-center">
                        <v-icon color="white" size="75">mdi-file-document-outline</v-icon>
                    </v-card-title>
                    <v-card-title class="justify-center text-center"
                        style="color:white;font-weight:600;word-break:normal;">
                        After Funeral Guide
                    </v-card-title>
                </v-card>
            </v-col>
            <v-col :cols="isMobile() ? '6' : '4'" :class="isMobile() ? 'pa-5' : 'pa-10'" style="justify-items:center;">
                <v-card @click="openSection(2)" elevation="8"
                    style="width:300px;background-color:#91B460;min-height:210px;">
                    <v-card-title class="justify-center text-center">
                        <v-icon color="white" size="75">mdi-file-document-edit-outline</v-icon>
                    </v-card-title>
                    <v-card-title class="justify-center text-center"
                        style="color:white;font-weight:600;word-break:normal;">
                        Registration of Death
                    </v-card-title>
                </v-card>
            </v-col>
            <v-col :cols="isMobile() ? '6' : '4'" :class="isMobile() ? 'pa-5' : 'pa-10'" style="justify-items:center;">
                <v-card @click="openSection(3, true)" elevation="8"
                    style="width:300px;background-color:#91B460;min-height:210px;">
                    <v-card-title class="justify-center text-center">
                        <v-icon color="white" size="75">mdi-account</v-icon>
                    </v-card-title>
                    <v-card-title class="justify-center text-center"
                        style="color:white;font-weight:600;word-break:normal;">
                        People / Organisations to Notify
                    </v-card-title>
                </v-card>
            </v-col>
            <v-col :cols="isMobile() ? '6' : '4'" :class="isMobile() ? 'pa-5' : 'pa-10'" style="justify-items:center;">
                <v-card @click="openSection(4)" elevation="8"
                    style="width:300px;background-color:#91B460;min-height:210px;">
                    <v-card-title class="justify-center text-center">
                        <v-icon color="white" size="75">mdi-wallet-bifold-outline</v-icon>
                    </v-card-title>
                    <v-card-title class="justify-center text-center"
                        style="color:white;font-weight:600;word-break:normal;">
                        Financial Matters
                    </v-card-title>
                </v-card>
            </v-col>
            <v-col :cols="isMobile() ? '6' : '4'" :class="isMobile() ? 'pa-5' : 'pa-10'" style="justify-items:center;">
                <v-card @click="openSection(5, true)" elevation="8"
                    style="width:300px;background-color:#91B460;min-height:210px;">
                    <v-card-title class="justify-center text-center">
                        <v-icon color="white" size="75">mdi-gavel</v-icon>
                    </v-card-title>
                    <v-card-title class="justify-center text-center"
                        style="color:white;font-weight:600;word-break:normal;">
                        Legal<br v-if="isMobile()"> Affairs
                    </v-card-title>
                </v-card>
            </v-col>
            <v-col :cols="isMobile() ? '6' : '4'" :class="isMobile() ? 'pa-5' : 'pa-10'" style="justify-items:center;">
                <v-card @click="openSection(6, true)" elevation="8"
                    style="width:300px;background-color:#91B460;min-height:210px;">
                    <v-card-title class="justify-center text-center">
                        <v-icon color="white" size="75">mdi-heart-circle-outline</v-icon>
                    </v-card-title>
                    <v-card-title class="justify-center text-center"
                        style="color:white;font-weight:600;word-break:normal;">
                        Bereavement Support
                    </v-card-title>
                </v-card>
            </v-col>
            <v-col :cols="isMobile() ? '6' : '4'" :class="isMobile() ? 'pa-5' : 'pa-10'" style="justify-items:center;">
                <v-card @click="openSection(7)" elevation="8"
                    style="width:300px;background-color:#91B460;min-height:210px;">
                    <v-card-title class="justify-center text-center">
                        <v-icon color="white" size="75">mdi-lightbulb</v-icon>
                    </v-card-title>
                    <v-card-title class="justify-center text-center"
                        style="color:white;font-weight:600;word-break:normal;">
                        Explore Advice
                    </v-card-title>
                </v-card>
            </v-col>
        </v-row>
        <v-row v-if="section == 1" style="max-width:1200px;margin-left:auto;margin-right:auto;" class="checklist">
            <v-col cols="12">
                <div class="mb-5">
                    <v-btn @click="openSection(null)" text class="mb-10">
                        <v-icon class="mr-2">mdi-arrow-left</v-icon>
                        Back
                    </v-btn>
                </div>
            </v-col>
            <v-col :cols="isMobile() ? 12 : 6">
                <v-simple-table style="width:100%;">
                    <template v-slot:default>
                        <thead>
                            <tr>
                                <th
                                    style="color:white;background-color:#91B460;font-weight:600;font-size:20px;width:70%;">
                                    Registration of death
                                </th>
                                <!-- <th
                                    style="color:white;background-color:#91B460;font-weight:600;font-size:20px;width:30%;text-align:center;">
                                    Complete?
                                </th> -->
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>
                                    1. Get a Death Notification Form (DNF)
                                </td>
                                <!-- <td style="justify-items:center;">
                                    <v-checkbox height="5" width="5" />
                                </td> -->
                            </tr>
                            <tr>
                                <td>
                                    2. Provide date of birth, PPS number, occupation, martial status, address of the
                                    deceased
                                </td>
                                <!-- <td style="justify-items:center;">
                                    <v-checkbox height="5" width="5" />
                                </td> -->
                            </tr>
                            <tr>
                                <td>
                                    3. Photo ID of person who is registering the death
                                </td>
                                <!-- <td style="justify-items:center;">
                                    <v-checkbox height="5" width="5" />
                                </td> -->
                            </tr>
                            <tr>
                                <td>
                                    4. Take this information to any registrar for births, marriages & deaths in Ireland
                                    where the death will be registered
                                </td>
                                <!-- <td style="justify-items:center;">
                                    <v-checkbox height="5" width="5" />
                                </td> -->
                            </tr>
                        </tbody>
                    </template>
                </v-simple-table>
            </v-col>
            <v-col :cols="isMobile() ? 12 : 6">
                <v-simple-table style="width:100%;">
                    <template v-slot:default>
                        <thead>
                            <tr>
                                <th
                                    style="color:white;background-color:#91B460;font-weight:600;font-size:20px;width:70%;">
                                    People / Organisations to Notify
                                </th>
                                <!-- <th
                                    style="color:white;background-color:#91B460;font-weight:600;font-size:20px;width:30%;text-align:center;">
                                    Complete?
                                </th> -->
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>
                                    1. Contact the GP / Health Care / Hospitals that the deceased may have been attending
                                    if they were receiving community care health services
                                </td>
                                <!-- <td style="justify-items:center;">
                                    <v-checkbox height="5" width="5" />
                                </td> -->
                            </tr>
                            <tr>
                                <td>
                                    2. Contact the doctor / hospital if they deceased was awaiting any appointments.
                                </td>
                                <!-- <td style="justify-items:center;">
                                    <v-checkbox height="5" width="5" />
                                </td> -->
                            </tr>
                        </tbody>
                    </template>
                </v-simple-table>
            </v-col>
            <v-col :cols="isMobile() ? 12 : 6">
                <v-simple-table style="width:100%;">
                    <template v-slot:default>
                        <thead>
                            <tr>
                                <th
                                    style="color:white;background-color:#91B460;font-weight:600;font-size:20px;width:70%;">
                                    Department of Social Protection
                                </th>
                                <!-- <th
                                    style="color:white;background-color:#91B460;font-weight:600;font-size:20px;width:30%;text-align:center;">
                                    Complete?
                                </th> -->
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>
                                    1. You will need to notify the Department of Social Protection If the deceased was
                                    receiving any social welfare payments such as, illness benefit, jobseekers
                                    allowance.
                                    This also applies if someone was receiving a Carer's Allowance for looking after the
                                    deceased.
                                </td>
                                <!-- <td style="justify-items:center;">
                                    <v-checkbox height="5" width="5" />
                                </td> -->
                            </tr>
                            <tr>
                                <td>
                                    <small>
                                        <i>Surviving spouses or civil partners of pension recipients may be eligible for
                                            a
                                            continuation of the pension for six weeks following the death. There are
                                            other
                                            support that you might be eligible for, including a Exceptional Needs Grant
                                            for
                                            Funeral Expenses, and Widow's, Widower's, or surviving Civil Partner's
                                            Pension. To
                                            explore your options and ensure all necessary adjustments are made, you can
                                            contact
                                            the Department of Social Protection on 1890 500 000
                                        </i>
                                    </small>
                                </td>
                            </tr>
                        </tbody>
                    </template>
                </v-simple-table>
            </v-col>
            <v-col :cols="isMobile() ? 12 : 6">
                <v-simple-table style="width:100%;">
                    <template v-slot:default>
                        <thead>
                            <tr>
                                <th
                                    style="color:white;background-color:#91B460;font-weight:600;font-size:20px;width:70%;">
                                    Legal
                                </th>
                                <!-- <th
                                    style="color:white;background-color:#91B460;font-weight:600;font-size:20px;width:30%;text-align:center;">
                                    Complete?
                                </th> -->
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>
                                    1. Notify the deceased's solicitor if there is one and identify the existence of a will
                                </td>
                                <!-- <td style="justify-items:center;">
                                    <v-checkbox height="5" width="5" />
                                </td> -->
                            </tr>
                            <tr>
                                <td>
                                    2. Notify the executors named on the will
                                </td>
                                <!-- <td style="justify-items:center;">
                                    <v-checkbox height="5" width="5" />
                                </td> -->
                            </tr>
                            <tr>
                                <td>
                                    3. If there is no will, decide who to appoint as administrator to administer the
                                    deceased's estate.
                                </td>
                                <!-- <td style="justify-items:center;">
                                    <v-checkbox height="5" width="5" />
                                </td> -->
                            </tr>
                        </tbody>
                    </template>
                </v-simple-table>
            </v-col>
            <v-col :cols="isMobile() ? 12 : 6">
                <v-simple-table style="width:100%;">
                    <template v-slot:default>
                        <thead>
                            <tr>
                                <th
                                    style="color:white;background-color:#91B460;font-weight:600;font-size:20px;width:70%;">
                                    You may need to cancel the following
                                </th>
                                <!-- <th
                                    style="color:white;background-color:#91B460;font-weight:600;font-size:20px;width:30%;text-align:center;">
                                    Complete?
                                </th> -->
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>
                                    1. Standing orders / Direct Debits
                                </td>
                                <!-- <td style="justify-items:center;">
                                    <v-checkbox height="5" width="5" />
                                </td> -->
                            </tr>
                            <tr>
                                <td>
                                    2. Milk deliveries
                                </td>
                                <!-- <td style="justify-items:center;">
                                    <v-checkbox height="5" width="5" />
                                </td> -->
                            </tr>
                            <tr>
                                <td>
                                    3. Fuel Deliveries
                                </td>
                                <!-- <td style="justify-items:center;">
                                    <v-checkbox height="5" width="5" />
                                </td> -->
                            </tr>
                            <tr>
                                <td>
                                    4. Telephone and broadband
                                </td>
                                <!-- <td style="justify-items:center;">
                                    <v-checkbox height="5" width="5" />
                                </td> -->
                            </tr>
                            <tr>
                                <td>
                                    5. Mobile phone
                                </td>
                                <!-- <td style="justify-items:center;">
                                    <v-checkbox height="5" width="5" />
                                </td> -->
                            </tr>
                            <tr>
                                <td>
                                    6. Bin Collection
                                </td>
                                <!-- <td style="justify-items:center;">
                                    <v-checkbox height="5" width="5" />
                                </td> -->
                            </tr>
                            <tr>
                                <td>
                                    7. Rent
                                </td>
                                <!-- <td style="justify-items:center;">
                                    <v-checkbox height="5" width="5" />
                                </td> -->
                            </tr>
                            <tr>
                                <td>
                                    8. TV Licence
                                </td>
                                <!-- <td style="justify-items:center;">
                                    <v-checkbox height="5" width="5" />
                                </td> -->
                            </tr>
                            <tr>
                                <td>
                                    9. Postal Services (or have them re-directed)
                                </td>
                                <!-- <td style="justify-items:center;">
                                    <v-checkbox height="5" width="5" />
                                </td> -->
                            </tr>
                        </tbody>
                    </template>
                </v-simple-table>
            </v-col>
            <v-col :cols="isMobile() ? 12 : 6">
                <v-simple-table style="width:100%;">
                    <template v-slot:default>
                        <thead>
                            <tr>
                                <th
                                    style="color:white;background-color:#91B460;font-weight:600;font-size:20px;width:70%;">
                                    Financial
                                </th>
                                <!-- <th
                                    style="color:white;background-color:#91B460;font-weight:600;font-size:20px;width:30%;text-align:center;">
                                    Complete?
                                </th> -->
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>
                                    1. Notify banks, building society, credit union, post office, companies in
                                    which shares are held
                                </td>
                                <!-- <td style="justify-items:center;">
                                    <v-checkbox height="5" width="5" />
                                </td> -->
                            </tr>
                            <tr>
                                <td>
                                    2. Cancel Direct Debits/Standing Orders in the deceased's bank
                                </td>
                                <!-- <td style="justify-items:center;">
                                    <v-checkbox height="5" width="5" />
                                </td> -->
                            </tr>
                            <tr>
                                <td>
                                    3. Cancel subscriptions to clubs/groups/magazines
                                </td>
                                <!-- <td style="justify-items:center;">
                                    <v-checkbox height="5" width="5" />
                                </td> -->
                            </tr>
                            <tr>
                                <td>
                                    4. Cancel insurance policies, health insurance
                                </td>
                                <!-- <td style="justify-items:center;">
                                    <v-checkbox height="5" width="5" />
                                </td> -->
                            </tr>
                            <tr>
                                <td>
                                    5. Contact the pension provider if the deceased was receiving a workrelated or private
                                    pension
                                </td>
                                <!-- <td style="justify-items:center;">
                                    <v-checkbox height="5" width="5" />
                                </td> -->
                            </tr>
                            <tr>
                                <td>
                                    6. Contact any hire purchase or loan companies
                                </td>
                                <!-- <td style="justify-items:center;">
                                    <v-checkbox height="5" width="5" />
                                </td> -->
                            </tr>
                            <tr>
                                <td>
                                    7. Contact the tax office about possible tax refunds or credits
                                </td>
                                <!-- <td style="justify-items:center;">
                                    <v-checkbox height="5" width="5" />
                                </td> -->
                            </tr>
                        </tbody>
                    </template>
                </v-simple-table>
            </v-col>
            <v-col :cols="isMobile() ? 12 : 6">
                <v-simple-table style="width:100%;">
                    <template v-slot:default>
                        <thead>
                            <tr>
                                <th
                                    style="color:white;background-color:#91B460;font-weight:600;font-size:20px;width:70%;">
                                    Housing
                                </th>
                                <!-- <th
                                    style="color:white;background-color:#91B460;font-weight:600;font-size:20px;width:30%;text-align:center;">
                                    Complete?
                                </th> -->
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>
                                    1. If the deceased held a mortgage, change the name
                                    on the mortgage
                                </td>
                                <!-- <td style="justify-items:center;">
                                    <v-checkbox height="5" width="5" />
                                </td> -->
                            </tr>
                            <tr>
                                <td>
                                    2. Change the name on the house deeds
                                </td>
                                <!-- <td style="justify-items:center;">
                                    <v-checkbox height="5" width="5" />
                                </td> -->
                            </tr>
                            <tr>
                                <td>
                                    3. If living in rented accommodation, arrange to have the name on the tenancy agreement
                                    changed
                                </td>
                                <!-- <td style="justify-items:center;">
                                    <v-checkbox height="5" width="5" />
                                </td> -->
                            </tr>
                            <tr>
                                <td>
                                    4. Change the name on household utility bills
                                </td>
                                <!-- <td style="justify-items:center;">
                                    <v-checkbox height="5" width="5" />
                                </td> -->
                            </tr>
                            <tr>
                                <td>
                                    5. Contact An Post to re-direct post to the executor or administrator of the estate
                                </td>
                                <!-- <td style="justify-items:center;">
                                    <v-checkbox height="5" width="5" />
                                </td> -->
                            </tr>
                        </tbody>
                    </template>
                </v-simple-table>
            </v-col>
            <v-col :cols="isMobile() ? 12 : 6">
                <v-simple-table style="width:100%;">
                    <template v-slot:default>
                        <thead>
                            <tr>
                                <th
                                    style="color:white;background-color:#91B460;font-weight:600;font-size:20px;width:70%;">
                                    After Burial Grave Care
                                </th>
                                <!-- <th
                                    style="color:white;background-color:#91B460;font-weight:600;font-size:20px;width:30%;text-align:center;">
                                    Complete?
                                </th> -->
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>
                                    1. Temporary Grave Markers for the grave - type and nameplate
                                </td>
                                <!-- <td style="justify-items:center;">
                                    <v-checkbox height="5" width="5" />
                                </td> -->
                            </tr>
                            <tr>
                                <td>
                                    2. New headstone inscription drafted
                                </td>
                                <!-- <td style="justify-items:center;">
                                    <v-checkbox height="5" width="5" />
                                </td> -->
                            </tr>
                            <tr>
                                <td>
                                    3. Headstone Contractor appointed
                                </td>
                                <!-- <td style="justify-items:center;">
                                    <v-checkbox height="5" width="5" />
                                </td> -->
                            </tr>
                            <tr>
                                <td>
                                    4. New inscription text agreed
                                </td>
                                <!-- <td style="justify-items:center;">
                                    <v-checkbox height="5" width="5" />
                                </td> -->
                            </tr>
                            <tr>
                                <td>
                                    5. Grave floor reinstated
                                </td>
                                <!-- <td style="justify-items:center;">
                                    <v-checkbox height="5" width="5" />
                                </td> -->
                            </tr>
                            <tr>
                                <td>
                                    6. Cleaning of existing headstone
                                </td>
                                <!-- <td style="justify-items:center;">
                                    <v-checkbox height="5" width="5" />
                                </td> -->
                            </tr>
                            <tr>
                                <td>
                                    7. Recolouring of existing headstone lettering
                                </td>
                                <!-- <td style="justify-items:center;">
                                    <v-checkbox height="5" width="5" />
                                </td> -->
                            </tr>
                            <tr>
                                <td>
                                    8. Temporary Grave Surround for New Graves
                                </td>
                                <!-- <td style="justify-items:center;">
                                    <v-checkbox height="5" width="5" />
                                </td> -->
                            </tr>
                            <tr>
                                <td>
                                    9. Design of New Headstone for new graves
                                </td>
                                <!-- <td style="justify-items:center;">
                                    <v-checkbox height="5" width="5" />
                                </td> -->
                            </tr>
                        </tbody>
                    </template>
                </v-simple-table>
            </v-col>
            <v-col :cols="isMobile() ? 12 : 6">
                <v-simple-table style="width:100%;">
                    <template v-slot:default>
                        <thead>
                            <tr>
                                <th
                                    style="color:white;background-color:#91B460;font-weight:600;font-size:20px;width:70%;">
                                    After Funeral Events & Stationery
                                </th>
                                <!-- <th
                                    style="color:white;background-color:#91B460;font-weight:600;font-size:20px;width:30%;text-align:center;">
                                    Complete?
                                </th> -->
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>
                                    1. Monthly Memory Mass / Service / Events Booked
                                </td>
                                <!-- <td style="justify-items:center;">
                                    <v-checkbox height="5" width="5" />
                                </td> -->
                            </tr>
                            <tr>
                                <td>
                                    2. Notice of months memory/ public acknowledgements drafted for public
                                </td>
                                <!-- <td style="justify-items:center;">
                                    <v-checkbox height="5" width="5" />
                                </td> -->
                            </tr>
                            <tr>
                                <td>
                                    3. Notice of months memory event/ acknowledgement published in relevant
                                    places - newspaper/ online
                                </td>
                                <!-- <td style="justify-items:center;">
                                    <v-checkbox height="5" width="5" />
                                </td> -->
                            </tr>
                            <tr>
                                <td>
                                    4. Memorial stationery designer/ printer appointed
                                </td>
                                <!-- <td style="justify-items:center;">
                                    <v-checkbox height="5" width="5" />
                                </td> -->
                            </tr>
                            <tr>
                                <td>
                                    5. Gather information for memorial stationery - text, verses and images
                                    gathered
                                </td>
                                <!-- <td style="justify-items:center;">
                                    <v-checkbox height="5" width="5" />
                                </td> -->
                            </tr>
                            <tr>
                                <td>
                                    6. Decide on list of people and addresses to send to
                                </td>
                                <!-- <td style="justify-items:center;">
                                    <v-checkbox height="5" width="5" />
                                </td> -->
                            </tr>
                            <tr>
                                <td>
                                    7. Decide when to send
                                </td>
                                <!-- <td style="justify-items:center;">
                                    <v-checkbox height="5" width="5" />
                                </td> -->
                            </tr>
                            <tr>
                                <td>
                                    8. Book 1st anniversary mass/service/event
                                </td>
                                <!-- <td style="justify-items:center;">
                                    <v-checkbox height="5" width="5" />
                                </td> -->
                            </tr>
                        </tbody>
                    </template>
                </v-simple-table>
            </v-col>
        </v-row>
        <v-row v-if="section == 2" style="max-width:800px;margin-left:auto;margin-right:auto;">
            <v-col cols="12">
                <div class="mb-5">
                    <v-btn @click="openSection(null)" text class="mb-10">
                        <v-icon class="mr-2">mdi-arrow-left</v-icon>
                        Back
                    </v-btn>
                </div>
                <v-expansion-panels v-model="panel" multiple>
                    <v-expansion-panel>
                        <v-expansion-panel-header
                            style="color:white;background-color:#91B460;font-weight:600;font-size:20px;">
                            Registration of Death <!-- 2 -->
                        </v-expansion-panel-header>
                        <v-expansion-panel-content>
                            <br>
                            It is a legal requirement in Ireland that every death that takes place in the State
                            must be recorded and registered. Records of deaths in Ireland are held in the General
                            Register Office, which is the central civil repository for records relating to Births,
                            Marriages and Deaths in Ireland. You can apply for a copy of a death certificate in any
                            Registrar of Births, Marriages and Death or to the General Register Office.
                            <br><br>
                            A death can be registered in the office of any Registrar of Births, Marriages and
                            Death, irrespective of where the death occurs. The staff of the hospital (if the death
                            occurred in a hospital) or of your local health centre, will be able to tell you where you
                            can register the death.
                            <br><br>
                            Deaths must be registered as soon as possible after the death and no later than 3
                            months. The death is registered by a Qualified Informant. This is usually the next
                            of kin. Alternatively, it may be registered by a person who was present during the
                            death or final illness of the deceased person, or has knowledge of the required particulars.
                            Further information on who can register the death is available from the General Register
                            Office.
                            <br><br>
                            <h2>WHAT DO I NEED TO REGISTER THE DEATH?</h2>
                            In order to register a death, the Qualified Informant must bring a copy of the Death
                            Notification Form (DNF) to the Registrar's Office.
                            <br><br>
                            <h2>The Death Notification Form</h2>
                            The Death Notification Form is issued by the Medical Doctor/General Practitioner who
                            attended the patient in his/her last illness. This Doctor must have attended the
                            patient within 28 days prior to death and must be satisfied as to the medical cause of
                            death. Part 1 of the Form must be signed by the Medical Practitioner stating the cause of
                            death. Part 2 of the Form must be completed by the next-of-kin/relative giving additional
                            personal details of the deceased person, e.g. date of birth, PPS number, occupation, marital
                            status, address, etc, etc.
                            <br><br>
                            This form, having been completed by both the Doctor and the next-of-kin/relative, should
                            then be taken to any Registrar for Births, Marriages & Deaths in Ireland where the death
                            will then be registered. In addition, the Informant (person registering the death) must
                            provide certain information required for registration such as:
                            <v-simple-table :style="isMobile() ? 'width:100%;' : 'width:50%;'">
                                <template v-slot:default>
                                    <thead>
                                        <tr>
                                            <th class="text-left">
                                            </th>
                                            <th class="text-left">
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>Forename of the deceased person</td>
                                            <td>Surname of the deceased person</td>
                                        </tr>
                                        <tr>
                                            <td>Former residence of the deceased person</td>
                                            <td>Date of birth of the deceased person</td>
                                        </tr>
                                        <tr>
                                            <td>Age of the deceased person at last birthday</td>
                                            <td>PPS Number</td>
                                        </tr>
                                        <tr>
                                            <td>Date and place of death</td>
                                            <td>Qualification and residence of Informant</td>
                                        </tr>
                                    </tbody>
                                </template>
                            </v-simple-table>
                            <br><br>
                            For more information, please see the "Registering a Death" section on the Citizen's
                            Information website.
                            <br><br>
                            <h2>Where to Register</h2>
                            <br>
                            <div
                                :style="isMobile() ? 'width:100%;background-color:orange;padding:10px;' : 'width:70%;background-color:orange;padding:10px;'">
                                Cavan - Civil Registration Service - No walk-in service available. 049 437
                                3272<br>
                                Gate Lodge, Cavan General Hospital, Lisdarn, Cavan, H12 N889<br><br>
                                Monaghan - Civil Registration Service<br>
                                Local Health Care Unit, Rooskey, Monaghan, H18 DE78<br>
                                Afternoon by appointment only. 047 95543<br>
                                Walk-in service: Monday to Friday, 9.30am to 12.15pm<br><br>
                                Drogheda - Civil Registration Service<br>
                                Boyne Centre, Patrick Street, Drogheda, Louth, A92 F4EV<br>
                                Walk-in service for births, deaths and marriages registrations and buying
                                certificates:<br>
                                Monday to Friday, 9.30am to 12.15pm and 2pm to 4pm<br><br>
                                Dundalk - Civil Registration Service<br>
                                Louth Community Services, Dublin Road, Dundalk, Louth, A91 H274<br>
                                Walk-in service for births, deaths and marriages registrations and buying
                                certificates:<br>
                                Monday to Friday, 9.30am to 12.15pm and 2pm to 4pm<br><br>
                                Navan - Civil Registration Service<br>
                                Meath Enterprise Centre, Trim Road, Navan, Meath, C15 F6XT<br>
                                Walk-in service for births, deaths and marriages registrations and buying
                                certificates:<br>
                                Monday to Friday, 9.30am to 12.15pm and 2pm to 4pm
                            </div>
                            <br>
                            You will not be charged a fee to register a death. However, there is a fee of €20
                            for a
                            full standard death certificate.
                            <br><br>
                            <h2>What if I cannot get a death certificate?</h2>
                            Sometimes a death is referred to the coroner. This happens when the cause of death
                            is
                            not known and cannot be certified
                            by the doctor who attended upon the person who died. When this happens, you may have
                            to
                            wait some time before you get a
                            death certificate. The coroner's office gives you an interim death certificate,
                            which
                            you can use to notify the
                            Department of Social Protection, Revenue and other State or financial institutions.
                            <br><br>
                            <div
                                :style="isMobile() ? 'width:100%;background-color:orange;padding:10px;' : 'width:70%;background-color:orange;padding:10px;'">
                                <b>Local Coroners</b>
                                <br><br>
                                Cavan & Monaghan Coroner: Main Street Castleblayney, Co. Monaghan , A75
                                P822,<br>
                                042 9746557 drflanaganstaff@gmail.com<br><br>
                                Meath Coroner: Kenlis Place Kells Co. Meath , A82 XV40<br>
                                046 9280710 coroner@nlacy.ie
                            </div>
                        </v-expansion-panel-content>
                    </v-expansion-panel>
                    <v-expansion-panel>
                        <v-expansion-panel-header
                            style="color:white;background-color:#91B460;font-weight:600;font-size:20px;">
                            Post Mortems & The Role of the Coroner <!-- 4 -->
                        </v-expansion-panel-header>
                        <v-expansion-panel-content>
                            <br>
                            What is a Post-Mortem? A post-mortem is an examination of a patient after death. The
                            procedure is carried out by
                            a specially trained doctor, known as a pathologist, assisted by a mortician, a
                            specialist technician. It is
                            performed to establish the cause of death and/or to examine the effects of
                            treatment.
                            Unless the post-mortem
                            examination is directed by the Coroner your consent must be obtained before any
                            investigations are carried
                            out.<br><br>
                            <h2>Why is a Post-Mortem carried out?</h2>
                            When a death is due to natural causes, and the attending doctor can certify the
                            cause of
                            death, a post-mortem
                            examination, also called an autopsy, is not needed. However, if there is any aspect
                            of
                            the patient's illness
                            needing clarification or confirmation, the treating doctor or the next-of-kin may
                            think
                            a post-mortem is
                            desirable. The next-of-kin has the right to ask for or refuse a post-mortem
                            examination
                            in such a situation.
                            When the cause of death is unknown, and in certain other circumstances, the Coroner
                            is
                            obliged by law to order a
                            post-mortem, in which case the permission of the next-of-kin is not
                            required.<br><br>
                            CORONER'S POST-MORTEM: The Coroner is an independent official with legal
                            responsibility
                            for the medical-legal
                            investigation of certain deaths including those of a sudden, unexplained, unnatural
                            or
                            violent nature*. In some
                            circumstances this enquiry may necessitate a post-mortem examination. If the Coroner
                            directs that a post-mortem
                            examination take place the question of obtaining consent from the next-of-kin does
                            not
                            arise.<br><br>
                            When Will the Post-Mortem Report Be Available? Typically the post-mortem report
                            takes at
                            least six weeks to
                            complete. In certain complex cases the final report may take longer. Once the
                            post-mortem report has been
                            completed, further information including details of the post-mortem findings may be
                            obtained from:<br>
                            The Coroner in the case of a Coroner's post-mortem<br>
                            The consultant responsible for the care of the deceased at the hospital in the case
                            of a
                            consent
                            post-mortem.<br><br>
                            <h2>Who Issues the Death Certificate?</h2>
                            Cases for the Coroner will automatically be registered at the Register of Births,
                            Deaths
                            and Marriages office,
                            however, you will need to collect the death certificate yourself. There is a small
                            charge for the issue of all
                            death certificates.<br><br>
                            What is an Inquest? An inquest is a public inquiry by a Coroner into the
                            circumstances
                            surrounding a death. The
                            inquest will establish the identity of the deceased, how, when and where the death
                            occurred and the particulars
                            which will have to be registered by the Registrar of Deaths. Questions of civil or
                            criminal liability cannot be
                            considered or investigated at an inquest. No person can be blamed or exonerated. The
                            purpose of the inquest,
                            therefore, is to establish the facts surrounding the death and to place those facts
                            on
                            public record. An inquest
                            must be held when death is due to unnatural causes, otherwise the decision to hold
                            an
                            inquest is at the
                            discretion of the Coroner. The Coroner will issue the death certificate after an
                            inquest. Pending legislation
                            for Coroners aims to widen the scope of an inquest from investigating the medical
                            cause
                            of death to establishing
                            the circumstances in which the person died.<br><br>
                            <h2>Deaths Reportable to a Coroner</h2>
                            The following is a list of some deaths that are reportable to a Coroner:<br>
                            <ul>
                                <li>Sudden deaths from unknown causes</li>
                                <li>Any case where the cause of death is unknown</li>
                                <li>Any accident caused by any vehicle, aeroplane, train or boat</li>
                                <li>Where there are suspicious circumstances, violence or misadventure</li>
                                <li>Suicide</li>
                                <li>If the deceased has not been seen and treated by a registered medical
                                    practitioner within 28 days before
                                    death</li>
                                <li>Due to possible negligence, misconduct or malpractice</li>
                                <li>Death occurred within 24 hours of admittance to hospital</li>
                                <li>Any death which may have been caused by anaesthetic, diagnostic or
                                    therapeutic
                                    procedure</li>
                                <li>Any maternal death that occurs during or following pregnancy (up to six
                                    weeks
                                    after birth) or that might
                                    be reasonably related to pregnancy</li>
                                <li>Any death of a child in care</li>
                                <li>Any infant death, such as from Sudden Infant Death Syndrome</li>
                                <li>Certain stillbirths</li>
                                <li>If the deceased was in a mental health facility, in prison or in Garda or
                                    military custody</li>
                                <li>Deaths due to want of care, exposure or neglect</li>
                                <li>Any death due to accident at work, occupational disease or poisoning</li>
                                <li>Where a body is to be removed from the State</li>
                                <li>Where a body is unidentified</li>
                                <li>In certain circumstances where a body is to be cremated</li>
                                <li>Where a body or human remains is "discovered"</li>
                                <li>The death of persons in defined vulnerable groups</li>
                            </ul>
                        </v-expansion-panel-content>
                    </v-expansion-panel>
                </v-expansion-panels>
            </v-col>
        </v-row>
        <v-row v-if="section == 3" style="max-width:800px;margin-left:auto;margin-right:auto;">
            <v-col cols="12">
                <div class="mb-5">
                    <v-btn @click="openSection(null)" text class="mb-10">
                        <v-icon class="mr-2">mdi-arrow-left</v-icon>
                        Back
                    </v-btn>
                </div>
                <v-expansion-panels v-model="panel" multiple>
                    <v-expansion-panel>
                        <v-expansion-panel-header
                            style="color:white;background-color:#91B460;font-weight:600;font-size:20px;">
                            People / Organisations to Notify <!-- 3 -->
                        </v-expansion-panel-header>
                        <v-expansion-panel-content>
                            <br>
                            <b>Doctor or GP:</b> If the death occurs at home, contact the deceased's doctor to
                            obtain a medical certificate confirming the cause of death. If the death occurs in a
                            hospital, the hospital will provide this.<br><br>
                            <b>Registrar of Births, Deaths, and Marriages:</b> The death must be registered
                            within
                            three months. You will need to provide the medical certificate and information about
                            the
                            deceased. You can find your local registry office on the Health Service Executive
                            (HSE)
                            website.<br><br>
                            <b>Solicitor:</b> If there is a will, contact the solicitor who holds it. If there
                            is no
                            will, you may still need a solicitor to help with the estate.<br><br>
                            <b>Banks and Financial Institutions:</b> Notify the deceased's banks, building
                            societies, and other financial
                            institutions to freeze accounts and discuss the next steps.<br><br>
                            <b>Insurance Companies:</b> Contact any life insurance, health insurance, and other
                            insurance providers to inform them of the death and start any claim
                            processes.<br><br>
                            <b>Employer or Pension Provider:</b> If the deceased was employed or receiving a
                            pension, inform their employer or pension provider.<br><br>
                            <b>Social Welfare Services:</b> Contact the Department of Social Protection to
                            notify
                            them of the death and stop any social welfare payments. You may also inquire about
                            potential benefits or entitlements.<br><br>
                            <b>Revenue Commissioners:</b> Inform the tax office to update their records and
                            manage
                            any tax matters related to the deceased's estate.<br><br>
                            <b>Utility Companies and Service Providers:</b> Notify utility companies
                            (electricity,
                            gas, water), telephone, and internet providers to close or transfer
                            accounts.<br><br>
                            <b>Local Authorities:</b> If the deceased owned a property, inform the local
                            authority
                            for any council tax or property-related matters.<br><br>
                            <b>Clubs and Membership Organizations:</b> Inform any clubs, societies, or other
                            organizations of which the deceased was a member.<br><br>
                            <b>Post Office:</b> Redirect the deceased's mail if necessary.<br><br>
                            <b>Department of Foreign Affairs:</b> If the deceased held a passport, inform the
                            Department of Foreign Affairs to cancel it.<br>
                        </v-expansion-panel-content>
                    </v-expansion-panel>
                </v-expansion-panels>
            </v-col>
        </v-row>
        <v-row v-if="section == 4" style="max-width:800px;margin-left:auto;margin-right:auto;">
            <v-col cols="12">
                <div class="mb-5">
                    <v-btn @click="openSection(null)" text class="mb-10">
                        <v-icon class="mr-2">mdi-arrow-left</v-icon>
                        Back
                    </v-btn>
                </div>
                <v-expansion-panels v-model="panel" multiple>
                    <v-expansion-panel>
                        <v-expansion-panel-header
                            style="color:white;background-color:#91B460;font-weight:600;font-size:20px;">
                            Managing Bank Accounts, Insurance & Pensions <!-- 5 -->
                        </v-expansion-panel-header>
                        <v-expansion-panel-content>
                            <br>
                            When a person dies, their property passes to their personal representative.<br><br>
                            The personal representative then distributes the deceased person's assets (money,
                            possessions, and property) in accordance with the law, the will - if there is one -
                            or
                            the laws of intestacy if there is no will.<br><br>
                            <h2>Money in bank accounts</h2>
                            In the wake of a loved one's passing, managing financial affairs can be a daunting
                            task.
                            Recognising this, many banks and financial institutions in Ireland have established
                            dedicated bereavement departments. These specialised units are tasked with assisting
                            grieving families through the process of closing accounts, transferring funds, and
                            amending account details to reflect changes such as the removal or addition of
                            names.
                            Their role is to provide compassionate, efficient service to ease the administrative
                            burden during a difficult time. Families can contact these departments directly to
                            begin
                            the process of settling their loved one's financial matters with the understanding
                            and
                            support that they need.<br><br>
                            <h2>Bank accounts in joint names</h2>
                            If the bank account is in the joint names of the person who died and their spouse or
                            civil partner, the money can usually be transferred into the surviving spouse or
                            civil
                            partner's name. You will need the death certificate to do this. If the bank account
                            is
                            in the joint names of the deceased person and someone else, and the bank was given
                            instructions when the account was opened that the other person was to receive the
                            money
                            on the death of the deceased person, the money can be transferred into the
                            survivor's
                            name.<br><br>
                            If money is held in the deceased person's name only, then family members usually
                            cannot
                            get access until probate is granted to the personal representative.<br><br>
                            <h2>Credit Union Accounts</h2>
                            If the deceased person had a credit union account and completed a valid Nomination
                            Form
                            when opening the account, the money in the account up to a maximum of €23,000 goes
                            to
                            the nominated person or persons. Any remaining balance forms part of the deceased's
                            estate and is distributed in accordance with succession law.<br><br>
                            It will be worth checking if the deceased had Death Benefit Insurance (DBI) with
                            their
                            local Credit Union. This is a unique service offered by some Credit Unions to help
                            pay
                            for end-of-life expenses. It pays a fixed lump sum in the event of death. There are
                            some
                            terms and conditions to meet for inclusion, and you should ask your Credit Union
                            about
                            these and the amount of DBI lump sum currently payable. You should also check with
                            your
                            Credit Union how the premium is paid. Further details may be obtained from your
                            local
                            Credit Union.<br>
                        </v-expansion-panel-content>
                    </v-expansion-panel>
                    <v-expansion-panel>
                        <v-expansion-panel-header
                            style="color:white;background-color:#91B460;font-weight:600;font-size:20px;">
                            Financial Supports & Grants Available <!-- 6 -->
                        </v-expansion-panel-header>
                        <v-expansion-panel-content>
                            <br>
                            After bereavement, it may be of interest to you to know that there might be
                            financial
                            supports and certain grants and payments available to you. The Department of
                            Employment
                            Affairs and Social Protection (DEASP) provides certain one-off payments to help out
                            families during this difficult time. Your local DEASP's representative (formerly
                            known
                            as the Community Welfare Officer) can help you with these payments.<br><br>
                            <h2>EXCEPTIONAL NEEDS PAYMENTS FOR FUNERAL EXPENSES</h2>
                            These payments are paid under the Supplementary Welfare Allowance Scheme. You may be
                            eligible for an Exceptional Needs Payment to help you with the cost of a funeral if
                            your
                            income is low. Each case is decided on its merits by the Department of Employment
                            Affairs and Social Protection's representative (formerly known as the Community
                            Welfare
                            Officer) at your local office. Funeral expenses are an additional need, and you can
                            apply for assistance from your local Intreo centre. You should complete form SWA1
                            and
                            SWA5 which are available online and at your local Intreo centre.<br><br>
                            <h2>OCCUPATIONAL INJURIES BENEFIT SCHEME - SPECIAL FUNERAL GRANT</h2>
                            To be eligible for this grant, the death must have resulted from:<br>
                            <ul>
                                <li>an accident at work</li>
                                <li>an accident while travelling directly to or from work</li>
                                <li>an occupational disease</li>
                            </ul>
                            <br>
                            To apply, use form OB61, available from your local Intreo centre.
                            <br><br>
                            <h2>WIDOWED OR SURVIVING CIVIL PARTNER GRANT (€8000)</h2>
                            This grant is available where a death occurs of a parent with dependent children
                            (under
                            18 or over if in full-time education). The Grant is paid when the widowed parent
                            applies
                            for the Widow's, Widower's or Surviving Civil Partner's Pension.<br>
                        </v-expansion-panel-content>
                    </v-expansion-panel>
                    <v-expansion-panel>
                        <v-expansion-panel-header
                            style="color:white;background-color:#91B460;font-weight:600;font-size:20px;">
                            Taxation Management <!-- 7 -->
                        </v-expansion-panel-header>
                        <v-expansion-panel-content>
                            <br>
                            Notify the Revenue Commissioners: Inform the Revenue Commissioners (Irish Tax and
                            Customs) about the death. You
                            can do this by writing to the local Revenue office or contacting them via their
                            online services. This helps to
                            update their records and avoid any unnecessary tax-related correspondence.<br><br>
                            Obtain a Grant of Probate or Letters of Administration: If the deceased left a will,
                            the executor named in the
                            will must apply for a Grant of Probate. If there is no will, a close relative must
                            apply for Letters of
                            Administration. This legal document gives the executor or administrator the
                            authority to deal with the
                            deceased's assets, including their tax affairs.<br><br>
                            Complete a Tax Return for the Deceased: The deceased's income tax return must be
                            completed for the period from
                            January 1 to the date of death. This may include income from employment, pensions,
                            investments, or other
                            sources. You might need the help of a tax advisor or accountant to ensure all income
                            is accurately
                            reported.<br><br>
                            Capital Acquisitions Tax (CAT): If you inherit assets from the deceased, you may be
                            liable for Capital
                            Acquisitions Tax (CAT). This tax is applicable to inheritances and gifts. The amount
                            of tax payable depends on
                            the value of the inheritance and your relationship to the deceased. There are
                            specific thresholds and
                            exemptions, so it's important to understand your obligations. You may need to file
                            an IT38 form and pay any CAT
                            due.<br><br>
                            File Form CA24 (Inland Revenue Affidavit): This form must be completed and submitted
                            to the Revenue
                            Commissioners as part of the probate process. It provides details of the deceased's
                            assets and liabilities. The
                            form is necessary for the Revenue to assess any potential inheritance tax
                            liabilities.<br><br>
                            Distribute the Estate: Once all taxes and debts are paid, the remaining assets can
                            be distributed to the
                            beneficiaries according to the will or intestacy rules. Ensure that all tax matters
                            are settled before
                            distribution to avoid any complications.<br><br>
                            Handle Outstanding Debts and Liabilities: Pay any outstanding debts, including
                            credit card bills, loans, and
                            other liabilities. These debts must be settled before the estate can be distributed
                            to the
                            beneficiaries.<br><br>
                            Property Tax (Local Property Tax - LPT): If the deceased owned property, ensure that
                            any outstanding Local
                            Property Tax is paid. Notify the Revenue Commissioners of the change in ownership or
                            status of the
                            property.<br><br>
                            VAT and Business Taxes: If the deceased was self-employed or owned a business, you
                            will need to handle any
                            outstanding VAT returns, PAYE (Pay As You Earn), and other business taxes. This
                            might involve finalizing the
                            business accounts and ensuring all tax obligations are met.<br><br>
                            Seek Professional Advice: Taxation and estate management can be complex, so it is
                            advisable to seek assistance
                            from a solicitor, accountant, or tax advisor who specializes in probate and
                            inheritance tax. They can provide
                            guidance and help ensure that all tax matters are properly addressed.<br><br>
                            <h2>Tax in the year of death if you are not married or in a civil partnership</h2>
                            In the year of your death, your normal tax credits for the whole of that year apply.
                            If a refund of tax is due,
                            it can be claimed by the person responsible for finalising your financial
                            affairs.<br><br>
                            <a
                                href="https://www.citizensinformation.ie/en/birth-family-relationships/cohabiting-couples/">Unmarried
                                couples
                                are treated as single for tax purposes.</a>
                            <br><br>
                            <h2>Tax in the year of death if you are married or in a civil partnership</h2>
                            <br>
                            <a
                                href="https://www.citizensinformation.ie/en/money-and-tax/tax/income-tax/taxation-of-married-people/">If
                                your
                                spouse or civil partner dies, the way you are taxed in that year depends on how
                                you were taxed as a couple.
                                You may have been taxed through single assessment, separate assessment or joint
                                assessment. There is more
                                information on these options in taxation of married people and civil
                                partners.</a>
                            <br><br>
                            <h2>Single assessment</h2>
                            If you were both taxed as single people (called separate treatment) then the Widowed
                            Person's or Surviving Civil
                            Partner Tax Credit in Year of Bereavement (see below) will replace the personal tax
                            credit you had at the start
                            of the year.<br><br>
                            <h2>Joint assessment</h2>
                            <i>If a married couple is jointly assessed for tax, the spouse or civil partner
                                responsible for making tax
                                returns is known as the assessable spouse or nominated civil
                                partner.</i><br><br>
                            You can decide which of you is to be the assessable spouse or nominated civil
                            partner. If no one has been
                            nominated, the assessable person is the spouse or civil partner with the higher
                            income and remains the
                            assessable spouse or nominated civil partner until you change this.<br><br>
                            <b>If you are the assessable person and your spouse or civil partner
                                dies</b><br><br>
                            Under joint assessment, you are taxed as follows:<br><br>
                            You pay tax on your own total income for the full year plus the total income of your
                            spouse to the date of
                            death<br>
                            You are entitled to the full amount of the Married Person or Civil Partner Tax
                            Credit and the Employee Credit (2
                            Employee Credits if both have enough income)<br>
                            You may claim other tax credits due to both spouses in that year<br>
                            <b>If your spouse or civil partner was the assessable person</b><br><br>
                            From 1 January to the date of their death, your spouse or civil partner:<br><br>
                            Is taxable on their own total income and your total income for this period<br>
                            Is entitled to the full amount of the Married Person or Civil Partner Tax Credit and
                            the Employee Credit (2
                            Employee Credits if both have enough income)<br>
                            May claim a proportion of other credits up to date of death<br>
                            Has the tax rate bands that apply to a married couple<br><br>
                            From the date of death to the end of the tax year, you:<br><br>
                            Are assessed on your own income for this period<br>
                            Get the Widowed Person or Surviving Civil Partner's Tax Credit in the Year of
                            Bereavement and the Employee
                            Credit<br>
                            May claim tax credits for the period following the death<br>
                            Have the tax rate bands that apply to a single or widowed person<br><br>
                            <h2>Separate assessment</h2>
                            If you and your spouse or civil partner were taxed under separate assessment, the
                            Widowed Person's or Surviving
                            Civil Partner's Tax Credit will replace your personal tax credit. You may also be
                            entitled to unused tax credits
                            that were allocated to your spouse or civil partner.<br><br>
                            <h2>Tax credits after the year of death</h2>
                            If you are widowed or a surviving civil partner, you get an increased personal tax
                            credit after the year of
                            death.<br><br>
                            <a
                                href="https://www.citizensinformation.ie/en/money-and-tax/tax/income-tax-credits-and-reliefs/single-person-child-carer-tax-credit/">If
                                you are a parent, you can also get an additional Widowed Parent Tax Credit for
                                the 5 years following the
                                death. You may also be entitled to the Single Person Child Carer Credit.</a>
                            <br><br>
                            <h2>Widowed Person or Surviving Civil Partner's Tax Credit</h2>
                            If you are a widowed person or a surviving civil partner, you can claim an increased
                            personal tax credit. The
                            amount of the tax credit depends on whether or not you have dependent children and
                            when your spouse or civil
                            partner died.<br><br>
                            <b>If you do not have dependent children</b><br><br>
                            You get the Married Person or Civil Partner's Tax Credit in the year of death. This
                            is €3,750 in 2024 (€3,550 in
                            2023).<br><br>
                            In the years following the year of death, you will get the Widowed Person or
                            Surviving Civil Partner's (without
                            dependent children) Tax Credit. This is €2,415 in 2024 (€2,315 in 2023).<br><br>
                            <b>If you have dependent children</b><br><br>
                            You get the Married Person or Civil Partner's Tax Credit in the year of death. This
                            is €3,750 in 2024 (€3,550 in
                            2023).<br><br>
                            In the years following the year of death you will get:<br><br>
                            A Widowed Person or Surviving Civil Partner's (with dependent children) Tax Credit,
                            which is €1,875<br>
                            <a
                                href="https://www.citizensinformation.ie/en/money-and-tax/tax/income-tax-credits-and-reliefs/single-person-child-carer-tax-credit/">A
                                Single Person Child Carer Credit if eligible, which is €1,750</a>
                            <br><br>
                            <u>For the first 5 years after the year of death you will also get the Widowed
                                Parent Tax Credit – see
                                below.</u><br><br>
                            <u>You are not considered a widowed person with dependent children for tax purposes
                                if:</u><br>
                            <u>You are cohabiting with a partner or You no longer have dependent
                                children</u><br><br>
                            <u>In these cases you will get the Widowed Person or Surviving Civil Partner's Tax
                                Credit instead.</u><br><br>
                            <h2>Widowed Parent Tax Credit</h2>
                            <br><br>
                            <h2>The Widowed Parent Tax Credit is available for 5 years, beginning in the year
                                after the year of death.</h2>
                            <br><br>
                            <h2>The amount of the tax credit changes each year as follows:</h2>
                            <v-simple-table :style="isMobile() ? 'width:100%;' : 'width:50%;'">
                                <template v-slot:default>
                                    <thead>
                                        <tr>
                                            <th class="text-left">
                                                Years after death
                                            </th>
                                            <th class="text-left">
                                                Tax credit due
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>First year</td>
                                            <td>€3,600</td>
                                        </tr>
                                        <tr>
                                            <td>Second year</td>
                                            <td>€3,150</td>
                                        </tr>
                                        <tr>
                                            <td>Third year</td>
                                            <td>€2,700</td>
                                        </tr>
                                        <tr>
                                            <td>Fourth year</td>
                                            <td>€2,250</td>
                                        </tr>
                                        <tr>
                                            <td>Fifth year</td>
                                            <td>€1,800</td>
                                        </tr>
                                    </tbody>
                                </template>
                            </v-simple-table>
                            <br><br>
                            If you are bereaved in 2024 you will not get a Widowed Parent Tax Credit until
                            2025.<br><br>
                            You can only get one tax credit, regardless of how many children you have.<br><br>
                            To qualify for the Widowed Parent Tax Credit you must:<br><br>
                            Not have re-married by the start of the tax year<br>
                            Not be cohabiting with a partner<br>
                            Have a qualifying child living with you for some part of the tax year<br>
                            A qualifying child must be:<br><br>
                            Under 18 at the start of the tax year<br>
                            Over 18 but in full-time education or an apprenticeship for a minimum of 2 years<br>
                            Over 18 but became permanently incapacitated when under 21 or in full-time education
                            or training<br>
                            Your child may be an adopted child, a stepchild or any child that you support and
                            have custody of.<br><br>
                            <a href="https://www.revenue.ie/en/contact-us/index.aspx">Following the death of a
                                spouse, you should contact
                                Revenue to get the appropriate tax credits.</a><br><br>
                            <a href="https://www.ros.ie/myaccount-web/home.html">You can claim tax credits
                                online using Revenue's PAYE
                                Services which is accessed through Revenue's myAccount.</a>
                            <br>
                        </v-expansion-panel-content>
                    </v-expansion-panel>
                    <v-expansion-panel>
                        <v-expansion-panel-header
                            style="color:white;background-color:#91B460;font-weight:600;font-size:20px;">
                            Dealing with the Department of Social Protection <!-- 8 -->
                        </v-expansion-panel-header>
                        <v-expansion-panel-content>
                            <br>
                            All deaths must be registered with the General Register Office (GRO) within three
                            months. A death can be
                            registered at any Civil Registration Office and is usually registered by a family
                            member.<br><br>
                            <h2>Death of a person in receipt of a social welfare payment</h2>
                            If a person dies while in receipt of a social welfare payment, their death should be
                            notified to the section of
                            the department dealing with their payment as soon as possible.<br>
                            You can find the contact details for each section of the department on the Social
                            Welfare Phone Number
                            Directory.<br><br>
                            <h2>Continuation of social welfare payments after death</h2>
                            In certain circumstances, social welfare payments will continue to be paid to the
                            spouse, civil partner,
                            cohabitant or carer of the person who died for 6 weeks after their death. Whether a
                            payment will continue to be
                            paid after death will depend on if the claimant meets the qualifying criteria.
                            Payments that may continue to be
                            paid after death include:<br><br>
                            <a href="https://www.gov.ie/en/service/93cd55-back-to-work-family-dividend/">Back to
                                Work Family
                                Dividend</a><br>
                            <a href="https://www.gov.ie/en/service/619221-blind-pension/">Blind Pension</a><br>
                            <a href="https://www.gov.ie/en/service/2432ba-carers-allowance/">Carer's Allowance
                                (continues to be paid for 12
                                weeks after death)</a><br>
                            <a href="https://www.gov.ie/en/service/455c16-carers-benefit/">Carer's
                                Benefit</a><br>
                            <a href="https://www.gov.ie/en/service/df6811-disability-allowance/">Disability
                                Allowance</a><br>
                            <a href="https://www.gov.ie/en/service/30fac9-domiciliary-care-allowance/">Domiciliary
                                Care Allowance (continues
                                to be paid for 12 weeks after death)</a><br>
                            <a href="https://www.gov.ie/en/service/7cfa30-farm-assist/">Farm Assist</a><br>
                            <a href="https://www.gov.ie/en/service/ddf6e3-illness-benefit/">Illness
                                Benefit</a><br>
                            <a href="https://www.gov.ie/en/service/23f2b0-incapacity-supplement/">Incapacity
                                Supplement</a><br>
                            <a href="https://www.gov.ie/en/service/fcdb1e-injury-benefit/">Injury
                                Benefit</a><br>
                            <a href="https://www.gov.ie/en/service/d148b9-invalidity-pension/">Invalidity
                                Pension</a><br>
                            <a href="https://www.gov.ie/en/service/1306dc-jobseekers-allowance/">Jobseeker's
                                Allowance</a><br>
                            <a href="https://www.gov.ie/en/service/1221b0-jobseekers-benefit/">Jobseeker's
                                Benefit</a><br>
                            <a href="https://www.gov.ie/en/service/e6f908-state-pension-contributory/">State
                                Pension (Contributory)</a><br>
                            <a href="https://www.gov.ie/en/service/e21eee-state-pension-non-contributory/">State
                                Pension
                                (Non-contributory)</a><br>
                            <a href="https://www.gov.ie/en/service/08bb21-working-family-payment/">Working
                                Family Payment</a><br><br>
                            Death of a person receiving care.If you are in receipt of Carer's Allowance, Carer's
                            Benefit or Domiciliary Care
                            Allowance and the person you are caring for dies, you must inform the section of the
                            department dealing with
                            your payment as soon as possible.<br><br>
                            You may continue to receive:<br>
                            Carer's Allowance for 12 weeks after death<br>
                            Carer's Benefit for 6 weeks after death<br>
                            Domiciliary Care Allowance for 12 weeks after death<br><br>
                            <h2>Death of a qualified adult or child</h2>
                            If you are getting a social welfare payment that includes an Increase for a
                            Qualified Adult or an Increase for a
                            Qualified Child and the qualified person dies, you must inform the section of the
                            department dealing with your
                            payment as soon as possible.<br><br>
                            You may continue to get the increased rate of payment for 6 weeks after the
                            qualified person's death.<br><br>
                            <h2>The steps to take while and after contacting the Department of Social
                                Protection.</h2><br>
                            <b>1. Notify the Department of Social Protection</b><br>
                            Immediate Notification: Contact the DSP to inform them of your loved one's death as
                            soon as possible. This will
                            prevent overpayments and help you understand any entitlements or
                            responsibilities.<br>
                            Local Intreo Centre: Visit or call your nearest Intreo Centre or Social Welfare
                            Office.<br>
                            Phone Numbers: You can call the DSP at 1890 800 024 (from within Ireland) or +353 1
                            704 3000 (from abroad).<br>
                            Online Information: More details can be found on the Department of Social Protection
                            website.<br><br>
                            <b>2. Obtain and Submit Necessary Documentation</b><br>
                            Death Certificate: You will need to provide a copy of the death certificate. This
                            can be obtained from the
                            Registrar of Births, Deaths, and Marriages.<br>
                            Personal Identification: Provide proof of your identity and, if applicable, proof of
                            your relationship to the
                            deceased.<br>
                            Proof of Address: Provide documentation showing the deceased's address and your own
                            address if needed.<br><br>
                            <b>3. Assess Current Social Welfare Payments</b><br>
                            Identify Payments: Determine which social welfare payments the deceased was
                            receiving, such as pensions,
                            disability allowances, or other benefits.<br>
                            Stop Payments: Notify the DSP to stop any payments to avoid overpayments, which may
                            need to be repaid.<br><br>
                            <b>4. Apply for Bereavement Benefits</b><br>
                            Bereavement Grant: Although the Bereavement Grant has been discontinued, you might
                            still want to check if there
                            are any remaining entitlements or supports available.<br>
                            Widow's/Widower's/Surviving Civil Partner's Pension: If you are the spouse or civil
                            partner of the deceased, you
                            may be eligible for this pension. To apply, you'll need to fill out the appropriate
                            form and provide supporting
                            documents such as the death certificate and marriage or civil partnership
                            certificate.<br><br>
                            <b>5. Manage Ongoing Social Welfare Issues</b><br>
                            Transfer of Benefits: If you were dependent on the deceased, inquire about
                            transferring or applying for new
                            benefits. For example, if you were receiving benefits as a qualified adult or
                            dependent, discuss your options
                            with the DSP.<br>
                            Supplementary Welfare Allowance: You might be eligible for this temporary payment
                            while your financial situation
                            is reassessed.<br><br>
                            <b>6. Complete and Submit Forms</b><br>
                            Application Forms: Obtain and fill out any necessary application forms from the DSP.
                            These forms can often be
                            downloaded from the DSP website or obtained from your local Intreo Centre.<br>
                            Supporting Documents: Ensure all required documents are attached, such as the death
                            certificate, proof of
                            identity, and any other relevant paperwork.<br>
                            Submission: Submit the forms and documents either in person, by post, or through an
                            online portal if
                            available.<br><br>
                            <b>7. Seek Assistance</b><br>
                            Social Welfare Advisors: Advisors at your local Intreo Centre or Social Welfare
                            Office can help you navigate the
                            process, fill out forms, and understand your entitlements.<br>
                            Community Welfare Officer: In certain cases, you may need to speak with a Community
                            Welfare Officer for
                            immediate financial assistance or advice on benefits.<br><br>
                            <b>8. Follow Up</b><br>
                            Confirmation: After submitting your application and documents, follow up with the
                            DSP to confirm receipt and
                            check the status of your claims or notifications.<br>
                            Record Keeping: Keep copies of all correspondence, forms, and documents you send or
                            receive. This will help if
                            there are any discrepancies or if further clarification is needed.<br><br>
                            <b>9. Additional Considerations</b><br>
                            Pensions and Retirement Accounts: If the deceased had private pensions or retirement
                            savings, notify the
                            relevant institutions and manage these accounts according to their policies and your
                            legal rights.<br>
                            Estate Matters: Work with a solicitor to handle the deceased's estate, including the
                            distribution of assets and
                            any legal matters.<br><br>
                            <b>10. Emotional and Community Support</b><br>
                            Bereavement Counseling: Consider accessing counseling services to help you cope with
                            your loss. The Irish
                            Hospice Foundation and other organizations offer bereavement support.<br>
                            Community Support: Local community groups and support networks can provide practical
                            help and emotional support
                            during this difficult time.<br>
                        </v-expansion-panel-content>
                    </v-expansion-panel>
                    <v-expansion-panel>
                        <v-expansion-panel-header
                            style="color:white;background-color:#91B460;font-weight:600;font-size:20px;">
                            Housing and Loans <!-- 9 -->
                        </v-expansion-panel-header>
                        <v-expansion-panel-content>
                            <br>
                            Dealing with housing matters after the loss of a loved one can be daunting.
                            Understanding your responsibilities
                            and options is crucial in managing both owned and rented properties. This guide aims
                            to clarify the steps you
                            should take and the rights you retain, helping to ease the burden during this
                            difficult period.<br><br>
                            <b>1. If the Deceased Owned a Home</b><br>
                            Title and Mortgage: If the home was owned jointly, the property typically passes
                            directly to the co-owner. If
                            owned solely, it becomes part of the estate and will be handled according to the
                            deceased's will or intestacy
                            laws.<br>
                            Immediate Steps: Secure the property and continue paying the mortgage to avoid
                            penalties. Contact the mortgage
                            lender to discuss the situation; they may offer a grace period.<br>
                            Long-term Considerations: Decide whether to keep, sell, or transfer the property. If
                            keeping, ensure the
                            mortgage can be managed by the estate or the beneficiaries.<br><br>
                            <b>2. If the Deceased Rented a House</b><br>
                            Notify the Landlord: Inform the landlord about the death as soon as possible. The
                            lease agreement typically
                            dictates what happens next—some may allow for early termination due to death.<br>
                            Lease Obligations: Understand any lease continuation obligations. If someone else
                            will continue living in the
                            rental, arrangements should be made to transfer the lease to their name.<br>
                            Deposit: Inquire about how and when the security deposit will be returned,
                            considering any estate
                            settlements.<br><br>
                            <b>3. If Living in a Housing Agency Property</b><br>
                            Report the Death: Contact the housing agency promptly to report the death. Provide
                            them with a copy of the death
                            certificate.<br>
                            Succession Rights: Discuss succession rights if the deceased was the sole tenant.
                            Each agency has its own policy
                            regarding tenancy transfers to a qualifying relative or cohabitant.<br>
                            Future Tenancy: If you qualify for succession, ensure you understand any new terms
                            or conditions that may
                            apply.<br><br>
                            <b>4. Managing Mortgages</b><br>
                            Communication with Lenders: Reach out to the mortgage provider to inform them of the
                            death. Provide necessary
                            documentation and discuss potential modifications to the mortgage terms.<br>
                            Insurance Policies: Check if there was a mortgage protection insurance policy in
                            place that covers the remaining
                            balance in the event of death.<br>
                            Financial Advice: Consider consulting with a financial advisor to understand the
                            implications of taking over a
                            mortgage or selling the property.<br><br>
                            <b>5. Rights of Surviving Residents</b><br>
                            Legal Advice: It's advisable to seek legal advice to fully understand the rights of
                            those left behind,
                            particularly regarding property and tenancy laws.<br>
                            Support Systems: Look into local support services that can offer advice and
                            assistance during the
                            transition.<br><br>
                            <h2>Mortgages and the steps to take in more detail.</h2><br>
                            <b>1. Notify Relevant Parties:</b><br>
                            Mortgage Lender: Inform your mortgage lender about your spouse's death as soon as
                            possible. They can provide
                            guidance on your options and may offer temporary relief.<br>
                            Insurance Companies: Notify your home insurance provider and check if there are any
                            provisions for dealing with
                            death or changes in ownership.<br><br>
                            <b>2. Review the Mortgage Agreement:</b><br>
                            Check for Co-Ownership: Determine whether you and your spouse were co-owners of the
                            property. This will affect
                            how the mortgage and property ownership are handled.<br>
                            Review Mortgage Terms: Look at your mortgage agreement to understand any clauses
                            related to death, such as
                            insurance coverage or loan forgiveness.<br><br>
                            <b>3. Evaluate Your Financial Situation:</b><br>
                            Assess Your Income: Calculate your current income and expenses to understand your
                            ability to continue making
                            mortgage payments.<br>
                            Check for Life Insurance: Determine if your spouse had a life insurance policy that
                            could help pay off or reduce
                            the mortgage balance.<br><br>
                            <b>4. Explore Mortgage Options:</b><br>
                            Mortgage Protection Insurance: If your spouse had mortgage protection insurance,
                            this may cover some or all of
                            the mortgage balance.<br>
                            Refinancing: Consider refinancing the mortgage to adjust the terms or reduce monthly
                            payments based on your
                            current financial situation.<br>
                            Repayment Plans: Speak with your lender about setting up a repayment plan if you're
                            struggling to make
                            payments.<br><br>
                            <b>5. Legal and Estate Considerations:</b><br>
                            Probate Process: The property may need to go through probate if it wasn't held in
                            joint tenancy or if there are
                            other estate issues.<br>
                            Executor or Administrator: The executor or administrator of your spouse's estate
                            will need to manage the
                            estate's assets, including the property.<br>
                            Property Transfer: If you're the beneficiary or sole owner, ensure that the property
                            is legally transferred into
                            your name. You may need to update the title deed.<br><br>
                            <b>6. Consider Your Living Arrangements:</b><br>
                            Stay or Sell: Decide whether you want to stay in the current home or if selling it
                            would be a better option.
                            Consider factors like emotional attachment, financial feasibility, and maintenance
                            costs.<br>
                            Downsizing: If staying in the home is not feasible, you might consider downsizing to
                            a more affordable
                            property.<br><br>
                            <b>7. Seek Professional Advice:</b><br>
                            Financial Advisor: Consult a financial advisor to help with budgeting, managing
                            debts, and exploring financial
                            options.<br>
                            Legal Advisor: Speak with an attorney specializing in estate planning or probate law
                            to navigate the legal
                            aspects of property and mortgage management.<br>
                            Mortgage Advisor: A mortgage advisor can offer insights into refinancing, repayment
                            options, and other financial
                            solutions.<br><br>
                            <b>8. Update Your Estate Plan:</b><br>
                            Review and Revise: Update your will, estate plan, and any beneficiary designations
                            to reflect your new
                            circumstances.<br>
                            Power of Attorney: Ensure any necessary legal documents, such as a power of attorney
                            or advanced directives, are
                            updated.<br><br>
                            <b>9. Take Care of Yourself:</b><br>
                            Emotional Support: Don't forget to seek emotional support during this challenging
                            time. Consider talking to a
                            counselor or joining a support group.<br><br>
                            <h2>Loans and debts</h2>
                            You may be concerned that the person who died left behind debts that have to be
                            repaid.<br><br>
                            Personal loans, credit cards and overdrafts<br>
                            You are only liable (responsible) for those debts that you yourself have signed for.
                            If you are having
                            difficulty making repayments on a loan in your own name or in joint names, you
                            should let the company know what
                            has happened and ask for time to work out what you can afford, given your changed
                            circumstances.<br><br>
                            Repayments on a loan in the sole name of the person who died should be paid out of
                            the estate. If you are asked
                            to take over payments on a loan that is in the sole name of the person who died, you
                            do not have to do
                            this.<br><br>
                            Get advice if you are not sure what to do. MABS provides free, confidential and
                            independent advice on a range of
                            financial matters. You can contact MABS on 0818 07 2000, 9am to 8pm, Monday to
                            Friday.<br><br>
                            Credit cards, bank overdrafts and personal loans are known as ‘unsecured debts'.
                            With unsecured debt, the
                            creditor (the institution that is owed) does not have the right to take a particular
                            item of property if the
                            debtor does not pay.<br><br>
                            These debts are repaid from the estate. You only have to repay debts yourself if
                            they were taken out in joint
                            names. If there is not enough money in the estate to repay unsecured debts that were
                            taken out solely by the
                            person who has died, and the debts are not covered by an insurance policy, the
                            creditor cannot ask anyone else
                            to repay the debt<br><br>
                            <h2>Financial difficulties</h2>
                            If you are experiencing financial difficulties after a bereavement, you can get
                            advice from your local MABS.
                            Free Legal Advice clinics are available throughout the country and they operate a
                            telephone information and
                            referral line on (01) 906 10 10. You can find details of what clinics are available
                            through your local Citizens
                            Information Centre, see centres.citizensinformation.ie
                            <br>
                        </v-expansion-panel-content>
                    </v-expansion-panel>
                </v-expansion-panels>
            </v-col>
        </v-row>
        <v-row v-if="section == 5" style="max-width:800px;margin-left:auto;margin-right:auto;">
            <v-col cols="12">
                <div class="mb-5">
                    <v-btn @click="openSection(null)" text class="mb-10">
                        <v-icon class="mr-2">mdi-arrow-left</v-icon>
                        Back
                    </v-btn>
                </div>
                <v-expansion-panels v-model="panel" multiple>
                    <v-expansion-panel>
                        <v-expansion-panel-header
                            style="color:white;background-color:#91B460;font-weight:600;font-size:20px;">
                            Wills, Legal Affairs, Estates & Probate <!-- 10 -->
                        </v-expansion-panel-header>
                        <v-expansion-panel-content>
                            <br>
                            The passing of a loved one brings not only emotional challenges but also the need to
                            navigate certain legal
                            processes. This guide introduces key terms and concepts essential in managing legal
                            affairs after a
                            funeral<br><br>
                            <b>1. Understanding the Estate:</b><br>
                            An estate encompasses all the assets and liabilities a person leaves behind after
                            passing away, including
                            property, money, debts, and possessions. Managing an estate involves gathering
                            assets, paying off debts, and
                            distributing the remainder according to the will or law.<br><br>
                            <b>2. Wills: A Key Document:</b><br>
                            A will is a legal document outlining how a person's estate should be distributed
                            after their death. It may
                            specify guardians for minor children and provisions for pets. If a will exists, it
                            serves as a roadmap for the
                            executor.<br><br>
                            <b>3. The Role of the Executor:</b><br>
                            An executor, named in the will, is tasked with carrying out the deceased person's
                            wishes. Responsibilities
                            include:<br><br>
                            Gathering and valuing the estate's assets.<br>
                            Settling any outstanding debts and taxes.<br>
                            Distributing assets to beneficiaries as per the will.<br>
                            Filing necessary court documents.<br>
                            The role of an executor can be complex, often requiring legal and financial
                            knowledge.<br><br>
                            <b>4. Probate: The Legal Process:</b><br>
                            Probate is the court-supervised process of validating a will and overseeing the
                            estate's distribution. It
                            involves proving the will's validity and administering the estate
                            accordingly.<br><br>
                            <b>5. When There is No Will: Intestacy and Succession:</b><br>
                            If your loved one did not leave a will, their estate is distributed according to the
                            rules of intestacy. In
                            Ireland, the Succession Act 1965 determines the order of inheritance, prioritising
                            spouses, children, and then
                            other relatives. Without a will, an administrator (similar to an executor) is
                            appointed to manage the estate's
                            affairs.<br><br>
                            <b>6. Inheritance Tax Considerations:</b><br>
                            Inheritances in Ireland may be subject to Capital Acquisitions Tax (CAT), commonly
                            known as inheritance tax. The
                            relationship between the deceased person and the beneficiary affects the tax-free
                            threshold. Seeking advice from
                            a solicitor on potential tax liabilities and available reliefs is advisable.<br><br>
                            <b>Seeking Professional Assistance:</b><br>
                            Estates can vary in complexity, and professional guidance is often essential. We
                            recommend consulting with a
                            family solicitor specialising in estate affairs. They can provide invaluable
                            assistance in navigating the legal
                            process, ensuring your loved one's wishes are honoured, and that legal requirements
                            are met.
                            <br>
                        </v-expansion-panel-content>
                    </v-expansion-panel>
                </v-expansion-panels>
            </v-col>
        </v-row>
        <v-row v-if="section == 6" style="max-width:800px;margin-left:auto;margin-right:auto;">
            <v-col cols="12">
                <div class="mb-5">
                    <v-btn @click="openSection(null)" text class="mb-10">
                        <v-icon class="mr-2">mdi-arrow-left</v-icon>
                        Back
                    </v-btn>
                </div>
                <v-expansion-panels v-model="panel" multiple>
                    <v-expansion-panel>
                        <v-expansion-panel-header
                            style="color:white;background-color:#91B460;font-weight:600;font-size:20px;">
                            Bereavement Supports <!-- 14 -->
                        </v-expansion-panel-header>
                        <v-expansion-panel-content>
                            <br>
                            Although grieving is a natural process that can be helped by the sympathy and
                            understanding of friends,
                            sometimes you may need support to work through it in a positive way.<br><br>
                            Friends don't always know how to help; they may be embarrassed by your grief; they
                            may even avoid meeting you in
                            the mistaken belief that a meeting may increase distress.<br><br>
                            It is quite normal to feel anger, guilt, fear, and depression as well as the awful
                            pain of loss. These feelings
                            need to be expressed rather than repressed, to be talked out, cried over, and
                            gradually put into
                            perspective.<br><br>
                            It helps the healing process to contact a trained listener and confront the very
                            strong feelings associated with
                            grief.<br><br>
                            <b>Supports Available</b><br><br>
                            We have compiled a list of support groups and the details of the various support
                            groups which are available
                            around Kingscourt as well as some of the national organisations.<br><br>
                            <h2>Kingscourt Bethany Bereavement Support Team</h2>
                            Available in the Parish Centre after 10am Mass each 1st Friday.<br>
                            There is a 5 week Healing Programme held each Lent and November in the Parish
                            Centre.<br>
                            One to one service available on request.<br><br>
                            Contact the Kingscourt Bethany Bereavement Support Team through the Parochial House
                            at 042-9667314<br><br>
                            <h2>Siobhan Shannon, Psychotherapist and Counsellor</h2>
                            Main Street, Kingscourt.<br>
                            087 351 3032<br>
                            siobhan4shannon@gmail.com<br><br>
                            <h2>Cavan Family Resource Centre delivers free and confidential Bereavement
                                Counselling in Carrickmacross
                                Workhouse.</h2>
                            Intense feelings, confused thoughts, and strong physical reactions are common to
                            bereaved people of all ages.
                            Many people find that the support and comfort of others, which is often available in
                            the early days of
                            bereavement, may be no longer there when the full loss and grief sets in. This is
                            where trained Bereavement
                            Counsellors can help.<br>
                            If you are interested in such counselling, or simply want to know more, please feel
                            free to contact Cavan Family
                            Resource Centre at 049 43 71 363 or email info@cfrc.ie<br><br>
                            <h2>Irish Hospice Association (IHA):</h2>
                            As a national leader in the development of bereavement care, the IHA are working in
                            collaboration with
                            bereavement care providers and charities to provide information, resources and
                            programmes for individuals,
                            families, communities and workplaces.<br><br>
                            Their free Bereavement Support Line, in partnership with the HSE, is there if you're
                            grieving and need comfort,
                            connection and support right now. It is free to call their Bereavement Support Line
                            1800 80 70 77 Mon - Fri 10am
                            -1pm<br><br>
                            They also have lots of other supports and resources which can be found at;
                            https://hospicefoundation.ie/our-supports-services<br><br>
                            <h2>Samaritans</h2>
                            Nationwide<br>
                            Call Freephone 116123<br>
                            www.samaritans.org<br><br>
                            <h2>Rainbows</h2>
                            Rainbows Ireland is a national support service for children and young people,
                            experiencing grief and loss
                            resulting from the death of someone close, parental separation and divorce.<br>
                            Call (01) 473 4175<br>
                            www.rainbowsreland.ie<br><br>
                            <h2>Barnardos Children's Bereavement Service</h2>
                            Barnardos Children's Bereavement Service, is a service for children and young people
                            who have lost someone close
                            to them — like a parent or a sibling — through death. While we can't shield children
                            from the reality of death
                            and the pain of loss, we can help them to understand, to grieve and to cope a little
                            better<br>
                            Call: (01) 473 2110 from 10am to 12pm Monday to Thursday<br>
                            Email: bereavement@barnardos.ie<br><br>
                            <h2>Anam Cara</h2>
                            Anam Cara is a national organisation, set up by bereaved parents to support bereaved
                            parents throughout Ireland.
                            They offer a number of services online and face to face. These include support
                            meetings, support groups, public
                            talks, remembrance events, an information line and more.<br><br>
                            Call: (01) 404 5378<br>
                            Email: info@anamcara.ie<br>
                            Website: www.anamcara.ie<br><br>
                            Recommended reading material or books on coping with loss.<br>
                            Information about online grief support forums or helplines.<br>
                        </v-expansion-panel-content>
                    </v-expansion-panel>
                </v-expansion-panels>
            </v-col>
        </v-row>
        <v-row v-if="section == 7" style="max-width:800px;margin-left:auto;margin-right:auto;">
            <v-col cols="12">
                <div class="mb-5">
                    <v-btn @click="openSection(null)" text class="mb-10">
                        <v-icon class="mr-2">mdi-arrow-left</v-icon>
                        Back
                    </v-btn>
                </div>
                <v-expansion-panels v-model="panel" multiple>
                    <v-expansion-panel>
                        <v-expansion-panel-header
                            style="color:white;background-color:#91B460;font-weight:600;font-size:20px;">
                            After Burial Grave & Headstone Care <!-- 11 -->
                        </v-expansion-panel-header>
                        <v-expansion-panel-content>
                            <br>
                            <h2>After the Funeral</h2>
                            Temporary Marker: Initially, you might want to place a temporary marker to identify
                            the grave. This could be
                            something simple, like a small plaque with their name, birth, and death dates. These
                            markers can be made of
                            wood, metal, or plastic and are often provided by the funeral home or cemetery.<br>
                            Headstone or Memorial: After the ground has settled (which usually takes a few
                            months), you can think about a
                            more permanent headstone or memorial. Check with the cemetery for any specific rules
                            about what's allowed.<br>
                            A grave surround can be a lovely way to frame the grave and keep it tidy. These
                            surrounds can be made from
                            stone, concrete, metal, or wood. Make sure to choose something durable and in line
                            with cemetery guidelines. If
                            needed, you can hire a professional to install it properly.<br><br>
                            <h2>Maintaining the Grave:</h2>
                            Over time, the soil on the grave will settle. Keep an eye on it and add more soil if
                            needed. Many cemeteries
                            take care of this for you, but it's good to monitor. Up tp 6 months<br>
                            Regular visits to tidy up the grave can be very comforting. This might involve
                            pulling weeds, trimming the
                            grass, or removing debris. Some cemeteries offer maintenance services if that's
                            easier for you.<br>
                            Removing Wilted or Dead Flowers: After the funeral, it's important to remove wilted
                            or dead flowers. This keeps
                            the grave looking respectful and tidy. Fresh flowers can be placed to replace them
                            if you wish.<br>
                            Every cemetery has its own set of rules about grave maintenance, decorations, and
                            memorials. It's a good idea to
                            familiarize yourself with these to avoid any issues.<br><br>
                            <h2>Permanent grave care options</h2>
                            With family decide on permanent grave surrounds, such as curbs, made from stone or
                            concrete. Headstones serve as
                            lasting memorials, commemorating your loved one with personalized inscriptions and
                            designs, and are typically
                            installed after the ground has settled.<br>
                            Choose a headstone mason and decide on the type of headstone and style & text you
                            would like inscribed.<br>
                            Note that these may take 3 months to have completed after the text has been deicded
                            on.<br>
                            <b>Consultation with a Headstone Mason:</b> Arrange a meeting with a reputable
                            headstone mason to discuss your
                            needs and preferences. This professional can provide guidance on what is feasible
                            based on the cemetery
                            regulations and ground conditions.<br>
                            <b>Decide on Materials and Design:</b> Headstones and surrounds can be crafted from
                            various materials, including
                            granite, limestone, marble, and more. Each material has its benefits and aesthetic
                            appeal. Consider the
                            durability and maintenance requirements of each material as well as aesthetic
                            preferences.<br>
                            <b>Consider the Size and Space:</b> Evaluate the size of the grave space—whether
                            it's a single or double
                            grave—and decide how much of the area you wish to cover with the headstone and
                            surround. This will influence the
                            design and scale of the monument.<br>
                            <b>Budget Planning:</b> Determine your budget for the headstone and surround. This
                            will help guide your choices
                            in terms of size, material, and design complexity.<br>
                            <b>Personalization Options:</b> Discuss how you would like to personalize the
                            headstone. This could include
                            inscriptions, designs, and other decorative elements that reflect the personality
                            and life of the deceased.<br>
                            <b>Approval and Installation:</b> Once you finalize the design and specifications,
                            the mason will need some time
                            to prepare and install the headstone and surround. This process typically takes
                            three to six months from the
                            decision point.<br><br>
                            <h2>Cemetery Regulations</h2>
                            Ensure to check with the cemetery about any specific regulations or restrictions
                            that may apply to grave
                            markers, surrounds, or maintenance practices.<br><br>
                            <h2>Memorial Decorations</h2>
                            <b>Floral Arrangements:</b><br>
                            <ul>
                                <li>Fresh Flowers: Regularly place fresh flowers to add color and life to the
                                    grave. Choose seasonal blooms
                                    or flowers that were meaningful to your loved one.</li>
                                <li>Artificial Flowers: Durable and long-lasting, artificial flowers are a good
                                    option for areas with harsh
                                    weather. Ensure they are made from weather-resistant materials.</li>
                            </ul>
                            <br>
                            <b>Plaques and Stones:</b>
                            <ul>
                                <li>Personalized Plaques: Add a personalized plaque with a special message,
                                    poem, or image that reflects
                                    your loved one's life and interests.</li>
                                <li>Memorial Stones: Decorative stones with engraved messages or symbols can
                                    enhance the appearance of the
                                    grave.</li>
                            </ul>
                            <br>
                            <b>Vases and Flower Holders:</b>
                            <ul>
                                <li>Attached Vases: Many headstones have attached vases for fresh or artificial
                                    flowers. If not, you can add
                                    a stand-alone vase or flower holder.</li>
                                <li>Designs: Choose vases that complement the headstone and withstand the
                                    elements.</li>
                            </ul>
                            <br>
                            <h2>Frequently asked Q&A</h2>
                            <br>
                            <b>When is it appropriate to add a new inscription to the headstone?</b><br>
                            Adding a new inscription to a headstone is often based more on personal or
                            psychological factors than on
                            practical ones. While it can technically be done at any time, many families choose
                            significant dates for the
                            deceased, such as:<br>
                            <ul>
                                <li>The month's memory</li>
                                <li>Birthdays</li>
                                <li>Anniversaries</li>
                                <li>Blessings of the graves</li>
                                <li>Christmas</li>
                            </ul>
                            <br>
                            These occasions provide meaningful moments to update the headstone, making the
                            memorial feel more connected to
                            ongoing family commemorations.<br><br>
                            <b>What is the lead time for adding an inscription?</b><br>
                            Once the text for the new inscription has been agreed upon, the typical lead time
                            for completion is about three
                            to four weeks. However, this timeline can vary based on the mason's availability. We
                            recommend contacting your
                            chosen mason well in advance of your desired completion date to ensure timely
                            service.<br><br>
                            <b>What other services are typically performed along with updating the
                                inscription?</b><br>
                            When updating a headstone's inscription, it is also common to perform several
                            maintenance tasks to restore the
                            grave's overall appearance. These can include:<br>
                            <ul>
                                <li>Filling in the grave space after it has settled</li>
                                <li>Updating any old inscription text</li>
                                <li>Cleaning the headstone and curbs</li>
                            </ul>
                            <br>
                            <b>When is the appropriate time to install a new headstone and surround?</b><br>
                            The installation of a new headstone and surround typically occurs about a year after
                            the burial, allowing the
                            ground sufficient time to settle. However, this timing is not mandatory, and many
                            families choose significant
                            dates such as anniversaries, birthdays, Christmas, or during blessings of the graves
                            to install new headstones.
                            We recommend waiting at least six months post-burial to ensure the ground has
                            adequately settled before
                            undertaking any permanent works.<br><br>
                            <b>How should families prepare for choosing a new headstone and surround?</b><br>
                            About three to six months after the burial, once the ground has settled, is an ideal
                            time to start considering
                            your options for a headstone and surround. Here are steps to guide you through this
                            process:<br><br>
                            <b>Consultation with a Headstone Mason:</b> Arrange a meeting with a reputable
                            headstone mason to discuss your
                            needs and preferences. This professional can provide guidance on what is feasible
                            based on the cemetery
                            regulations and ground conditions.<br>
                            <b>Decide on Materials and Design:</b> Headstones and surrounds can be crafted from
                            various materials, including
                            granite, limestone, marble, and more. Each material has its benefits and aesthetic
                            appeal. Consider the
                            durability and maintenance requirements of each material as well as aesthetic
                            preferences.<br>
                            <b>Consider the Size and Space:</b> Evaluate the size of the grave space—whether
                            it's a single or double
                            grave—and decide how much of the area you wish to cover with the headstone and
                            surround. This will influence the
                            design and scale of the monument.<br>
                            <b>Budget Planning:</b> Determine your budget for the headstone and surround. This
                            will help guide your choices
                            in terms of size, material, and design complexity.<br>
                            <b>Personalization Options:</b> Discuss how you would like to personalize the
                            headstone. This could include
                            inscriptions, designs, and other decorative elements that reflect the personality
                            and life of the deceased.<br>
                            <b>Approval and Installation:</b> Once you finalize the design and specifications,
                            the mason will need some time
                            to prepare and install the headstone and surround. This process typically takes
                            three to six months from the
                            decision point.<br><br>
                            <b>What are some additional considerations?</b>
                            Cemetery Regulations: Be sure to understand any specific rules or regulations that
                            the cemetery enforces
                            regarding headstone and surround installations.<br>
                            Long-term Maintenance: Consider the long-term care requirements for the chosen
                            materials to ensure the headstone
                            remains in good condition.<br>
                            Viewing Different Options: It's advisable to visit showrooms or cemeteries to see
                            different styles and types of
                            headstones and surrounds in person. This can help you make a more informed
                            decision.<br>
                        </v-expansion-panel-content>
                    </v-expansion-panel>
                    <v-expansion-panel>
                        <v-expansion-panel-header
                            style="color:white;background-color:#91B460;font-weight:600;font-size:20px;">
                            After Cremation Care <!-- 12 -->
                        </v-expansion-panel-header>
                        <v-expansion-panel-content>
                            <br>
                            We understand that making decisions after the loss of a loved one can be deeply
                            personal and challenging. This
                            guide is designed to help you navigate the various options for honouring their
                            memory following
                            cremation.<br><br>
                            <h2>1. Choosing an Urn</h2>
                            Types of Urns: Urns are available in various materials such as ceramic, wood, metal,
                            and biodegradable
                            materials. Each material offers unique benefits in terms of durability and
                            appearance.<br>
                            Factors to Consider: Consider the size of the urn, where it will be kept (indoors or
                            outdoors), and whether it
                            will be displayed or used for burial.<br><br>
                            <h2>2. Storing Ashes</h2>
                            At Home: Many families choose to keep the ashes at home in a decorative urn that
                            complements their decor.<br>
                            In a Columbarium: A columbarium is a wall with niches for urns. It's a communal
                            space that can be visited in
                            cemeteries or specific memorial areas.<br>
                            In a Family Grave: Ashes can be buried in a family plot at a cemetery. Check with
                            the cemetery about any
                            requirements or restrictions.<br><br>
                            <h2>3. Scattering Ashes</h2>
                            Choosing a Location: Pick a place that was significant to your loved one. Ensure
                            that you comply with local laws
                            regarding ash scattering.<br>
                            Scattering Ceremony: You can arrange a private or public ceremony. Consider reading
                            a poem, playing their
                            favourite music, or sharing memories during the scattering.<br>
                            Scatter Tubes and Biodegradable Urns: These are designed to simplify the process and
                            are environmentally
                            friendly.<br><br>
                            <h2>4. Additional Memorialization Options</h2>
                            Months Memory Mass and Burial: Some families choose to have a memorial mass one
                            month after the passing,
                            followed by burial of the ashes.<br>
                            Memorial Jewelry and Keepsakes: Ashes can be incorporated into jewellery like
                            pendants or rings, allowing you to
                            keep a part of your loved one close.<br><br>
                            <h2>5. Long-term Considerations</h2>
                            Maintenance and Upkeep: Consider the long-term care of any memorial site or urns
                            kept at home.<br>
                            Future Planning: Think about how future generations will maintain the memorial or
                            access the family plot.<br>
                        </v-expansion-panel-content>
                    </v-expansion-panel>
                    <v-expansion-panel>
                        <v-expansion-panel-header
                            style="color:white;background-color:#91B460;font-weight:600;font-size:20px;">
                            Digital Legacy, Online Accounts and Passwords <!-- 12 -->
                        </v-expansion-panel-header>
                        <v-expansion-panel-content>
                            <br>
                            <h2>1. Gather Information:</h2>
                            Account List: Try to collect a list of your loved one's online accounts and services. Look
                            for any
                            documents or notes where they may have listed their accounts, or check their email for
                            account
                            registration emails.<br>
                            Passwords: Locate any password lists or password managers they may have used. If they kept
                            passwords
                            in a digital format, such as a password manager, you'll need access to that tool.<br><br>
                            <h2>2. Accessing Accounts:</h2>
                            Digital Estate Plan: Check if your loved one had a digital estate plan or included
                            instructions for
                            managing their online accounts in their will.<br>
                            Legal Authority: If there is no plan in place, you may need legal authority, such as
                            probate, to
                            access accounts and manage their digital estate. Executors of the estate often have the
                            right to
                            handle these matters.<br><br>
                            <h2>3. Handling Social Media Accounts:</h2>
                            Notifications: Notify social media platforms of the person's death. Most platforms have
                            procedures
                            for memorializing or closing accounts:<br>
                            Facebook: Request to memorialize the account or have it removed.<br>
                            Instagram: Request account removal or memorialization.<br>
                            Twitter: Request account deactivation or removal.<br>
                            LinkedIn: Close or update the account as appropriate.<br><br>
                            <h2>4. Managing Financial and Subscription Accounts:</h2>
                            Notify Institutions: Inform banks, financial institutions, and any subscription services of
                            the
                            death. They will require documentation such as a death certificate and proof of executor
                            status.<br>
                            Cancel Subscriptions: Cancel or transfer subscriptions to services that the deceased was
                            subscribed
                            to, including streaming services, magazines, and online services.<br><br>
                            <h2>5. Email Accounts:</h2>
                            Access and Manage: Gain access to the email account using passwords or recovery options.
                            Review and
                            manage emails, including any recurring subscriptions or important communications.<br>
                            Close or Transfer: Depending on the needs, you may choose to close the email account or
                            transfer
                            important information to another account.<br><br>
                            <h2>6. Cloud Storage and Online Files:</h2>
                            Access Files: If your loved one used cloud storage services (e.g., Google Drive, Dropbox),
                            use their
                            login information to access and manage files.<br>
                            Download or Transfer: Download important files or transfer ownership of shared files if
                            needed.
                            Ensure you comply with privacy laws and respect the wishes of the deceased.<br><br>
                            <h2>7. Online Services and Memberships:</h2>
                            Review Accounts: Check for any online memberships, memberships to clubs, or other
                            services.<br>
                            Notify and Cancel: Inform service providers and cancel any memberships or services that are
                            no
                            longer needed.<br><br>
                            <h2>8. Legal and Privacy Considerations:</h2>
                            Privacy Laws: Be aware of privacy laws and data protection regulations, such as GDPR or
                            CCPA, which
                            may affect how you handle and access online accounts.<br>
                            Executor's Role: Executors of the estate may have legal authority to manage digital assets,
                            but it's
                            important to follow legal procedures and obtain necessary permissions.<br><br>
                            <h2>9. Consider Professional Help:</h2>
                            Digital Legacy Services: Some companies specialize in managing digital legacies and can
                            assist with
                            accessing and managing online accounts.<br>
                            Legal Advice: Consult with an attorney specializing in digital estate planning for guidance
                            on
                            handling online accounts and passwords.<br><br>
                            <h2>10. Document and Organize:</h2>
                            Keep Records: Maintain a record of all actions taken regarding digital accounts and assets.
                            Document
                            any communications with service providers and keep copies of relevant documents.<br><br>
                            <h2>11. Update Estate Planning Documents:</h2>
                            Future Planning: If you are managing your own estate planning, consider including
                            instructions for
                            your digital legacy in your will or a separate digital estate plan.
                            <br>
                        </v-expansion-panel-content>
                    </v-expansion-panel>
                    <v-expansion-panel>
                        <v-expansion-panel-header
                            style="color:white;background-color:#91B460;font-weight:600;font-size:20px;">
                            Months Memory / Remembrance Events <!-- 13 -->
                        </v-expansion-panel-header>
                        <v-expansion-panel-content>
                            <br>
                            Introduction Months Memory and Remembrance Events provide families and communities
                            an opportunity to come
                            together and honour a loved one who has passed away. These events serve as a
                            poignant reminder of the deceased,
                            fostering a shared space for grief and commemoration.<br><br>
                            Understanding Months Memory/Remembrance Events Months Memory Masses, traditionally
                            observed in the Roman
                            Catholic faith, mark one month after a person's passing. However, remembrance events
                            can take various forms and
                            are not confined to any one religious tradition. These gatherings might be deeply
                            personal and reflective or
                            more celebratory, depending on the wishes of the deceased and their
                            families.<br><br>
                            Adaptability Across Cultures While rooted in Roman Catholic traditions, the concept
                            of a remembrance event
                            transcends religious boundaries. Families of any or no religious affiliation can
                            organise gatherings that
                            respect their beliefs and cultural practices. Non-religious services, often referred
                            to as Celebrations of Life,
                            are becoming increasingly popular. These events focus on celebrating the deceased's
                            life and legacy through
                            stories, music, and shared memories in various venues like community halls, natural
                            settings, or even at
                            home.<br><br>
                            Celebrations of Life Ceremonies Unlike traditional religious services, Celebrations
                            of Life are less formal and
                            can be tailored to reflect the unique life of the deceased. These gatherings may
                            include activities that the
                            deceased enjoyed, display their favourite colours, or serve their favourite dishes.
                            The aim is to create a
                            living reflection of the loved one, allowing attendees to connect with their memory
                            in a personal and meaningful
                            way.<br><br>
                            Utilising Public Notices To announce these events and acknowledge the support
                            received during bereavement,
                            families often use public notices such as:<br><br>
                            <b>Months Memory Notices</b><br>
                            <b>Birthday Memorials</b><br>
                            <b>Acknowledgement Notices</b><br>
                            <b>Anniversary Notices</b><br><br>
                            These notices are published on platforms like RIP.ie, funeral directors' Facebook
                            pages, and in regional
                            newspapers. They serve not only as announcements but also as public thank-yous to
                            those who offered comfort,
                            help, and support before, during, and after the funeral. Acknowledgement notices are
                            particularly valued for
                            expressing gratitude, helping families articulate their appreciation for the
                            community's support during a
                            difficult time.<br><br>
                            Whether through a traditional Months Memory Mass or a personalised Celebration of
                            Life, these events play a
                            crucial role in the grieving process. They provide a structured moment to remember
                            the deceased, reflect on
                            their impact, and express gratitude to those who stood by the family. Remember,
                            these commemorations can be as
                            unique as the individual they honour and are invaluable in continuing their legacy
                            in the hearts of those they
                            touched.<br>
                        </v-expansion-panel-content>
                    </v-expansion-panel>
                </v-expansion-panels>
            </v-col>
        </v-row>
        <v-row class="mt-15 sticky-bottom">
            <v-col cols="12" class="text-center">
                <v-btn v-if="!isMobile()" @click="openSection(1)" text class="mx-3" small :disabled="section == 1"
                    :style="section == 1 ? 'background-color:#F5FBFF;text-transform:initial;' : 'text-transform:initial;'">
                    <v-icon color="darkgrey" class="mr-3">mdi-file-document-outline</v-icon>
                    After Funeral Guide
                </v-btn>
                <v-btn v-if="!isMobile()" @click="openSection(2)" text class="mx-3" small :disabled="section == 2"
                    :style="section == 2 ? 'background-color:#F5FBFF;text-transform:initial;' : 'text-transform:initial;'">
                    <v-icon color="darkgrey" class="mr-3">mdi-file-document-edit-outline</v-icon>
                    Registration of Death
                </v-btn>
                <v-btn v-if="!isMobile()" @click="openSection(3, true)" text class="mx-3" small :disabled="section == 3"
                    :style="section == 3 ? 'background-color:#F5FBFF;text-transform:initial;' : 'text-transform:initial;'">
                    <v-icon color="darkgrey" class="mr-3">mdi-file-document-edit-outline</v-icon>
                    People / Organisations to Notify
                </v-btn>
                <v-btn v-if="!isMobile()" @click="openSection(4)" text class="mx-3" small :disabled="section == 4"
                    :style="section == 4 ? 'background-color:#F5FBFF;text-transform:initial;' : 'text-transform:initial;'">
                    <v-icon color="darkgrey" class="mr-3">mdi-wallet-bifold-outline</v-icon>
                    Financial Matters
                </v-btn>
                <v-btn v-if="!isMobile()" @click="openSection(5, true)" text class="mx-3" small :disabled="section == 5"
                    :style="section == 5 ? 'background-color:#F5FBFF;text-transform:initial;' : 'text-transform:initial;'">
                    <v-icon color="darkgrey" class="mr-3">mdi-gavel</v-icon>
                    Legal Affairs
                </v-btn>
                <v-btn v-if="!isMobile()" @click="openSection(6, true)" text class="mx-3" small :disabled="section == 6"
                    :style="section == 6 ? 'background-color:#F5FBFF;text-transform:initial;' : 'text-transform:initial;'">
                    <v-icon color="darkgrey" class="mr-3">mdi-heart-circle-outline</v-icon>
                    Bereavement Support
                </v-btn>
                <v-btn v-if="!isMobile()" @click="openSection(7)" text class="mx-3" small :disabled="section == 7"
                    :style="section == 7 ? 'background-color:#F5FBFF;text-transform:initial;' : 'text-transform:initial;'">
                    <v-icon color="darkgrey" class="mr-3">mdi-lightbulb</v-icon>
                    Explore Advice
                </v-btn>
                <v-btn @click="backToTop()" text small>
                    <v-icon color="darkgrey" class="mr-3">
                        mdi-arrow-up
                    </v-icon>
                    Back to Top
                </v-btn>
            </v-col>
        </v-row>
        <v-row class="mt-15 py-10"
            :style="'color:white;background-color:' + (get_funeral_director ? get_funeral_director.color : '#193B5B')">
            <v-col :cols="isMobile() ? '1' : '3'" />
            <v-col :cols="isMobile() ? '7' : '3'" class="mt-5">
                <a style="color:white;">About</a><br>
                <a style="color:white;">Terms of Service</a><br>
                <a style="color:white;">Privacy Policy</a><br>
                <a style="color:white;">Contact Us</a>
            </v-col>
            <v-col :cols="isMobile() ? '4' : '6'" style="justify-items:center;">
                <v-img :src="require('../../assets/logo.png')" contain max-height="140" max-width="140" />
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
    components: {
    },
    props: [
    ],
    data: () => ({
        fd_id: null,
        panel: [],
        section: null,
    }),
    async mounted() {
        this.decodeBase62()
    },
    created() {
    },
    computed: {
        ...mapGetters({
            get_funeral_director: 'funeral_request/get_funeral_director',
        })
    },
    methods: {
        decodeBase62() {
            const chars = '0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz'
            let result = 0

            for (let i = 0; i < this.$router.currentRoute.params.fd_id.length; i++) {
                result = result * 62 + chars.indexOf(this.$router.currentRoute.params.fd_id[i])
            }

            this.fd_id = result.toString().substring(0, result.toString().length - 6)

            this.getData()
        },
        async getData() {
            await this.$axios.get(`non_auth/get_fd/${this.fd_id}`)
                .then(({ data }) => {
                    if (data.response) {
                        this.$store.dispatch('funeral_request/set_funeral_director', data.data)
                    }
                })
        },
        scrollToMainContent() {
            const element = document.getElementById('main-content')
            if (element) {
                element.scrollIntoView({ behavior: 'smooth' })
            }
        },
        openSection(selected_section, show_first_panel = false) {
            this.section = selected_section
            if (show_first_panel == true) {
                this.panel = [0]
            }
            else {
                this.panel = []
            }
            this.backToTop()
        },
        backToTop() {
            window.scrollTo({
                top: 0,
                left: 0,
            })
        },
        isMobile() {
            if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
                return true
            } else {
                return false
            }
        }
    },
}
</script>

<style scoped>
:deep(.mdi-chevron-down) {
    color: white !important;
}

.image-container {
    position: relative;
    display: inline-block;
}

.image-container v-img {
    display: block;
}

.text-overlay {
    position: absolute;
    bottom: 20%;
    left: 50%;
    transform: translateX(-50%);
    color: white;
    font-size: 1.5rem;
    font-weight: bold;
    border-radius: 5px;
    text-align: center;
    text-transform: initial;
    z-index: 1;
}

.image-container::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 30%;
    /* Adjust the height of the gradient */
    pointer-events: none;
    /* Ensure the gradient does not block interactions */
}

* {
    font-family: Arial, Helvetica, sans-serif;
}

.sticky-bottom {
    position: sticky;
    bottom: 0;
    background-color: white;
    /* Optional: Set a background color */
    z-index: 1;
    /* Optional: Ensure it stays above other content */
}

.checklist td {
    height: 30px !important;
}
</style>