import Vue from 'vue'
import VueRouter from 'vue-router'

/**
 * routes
 */
import Login from '../modules/Login/router/index.js'

/**Admin Funeral Director Routes */
import AdminDashboard from '../module_admin/dashboard/router/index.js'
import AdminFDComponent from '../module_admin/funeral_directors/router/index.js'
import AdminClientComponent from '../module_admin/clients/router/index.js'
import FDSurveryRoute from '../module_admin/survey/router/index.js'
import Visitors from '../module_admin/visitors/router/index.js'
import AdminFuneralNotice from '../module_admin/funeral_notice/router/index.js'
import AdminDocuments from '../module_admin/documents/router/index.js'
import AdminEmails from '../module_admin/emails/router/index.js'
import Solicitors from '../module_admin/solicitors/routes/index.js'
import Charities from '../module_admin/charities/routes/index.js'
/** end admin funeral director routes */

/**
 * Funeral Director Routes
 */
import FuneralDirectorDashboard from '../module_funeral_director/dashboard/router/index.js'
import FunderalDirectorClients from '../module_funeral_director/clients/router/index.js'
import FuneralDirectorSurvey from '../module_funeral_director/survey/router/index.js'
import FuneralDirectorDocuments from '../module_funeral_director/documents/router/index.js'
import FuneralDirectorFuneralNotice from '../module_funeral_director/funeral_notice/router/index.js'
import FuneralDirectorEmails from '../module_funeral_director/emails/router/index.js'
/**
 * End Funeral Director Routes
 */

/**
 * client routes
 */
import Payments from '../module_client/payment/router/index.js'
import ClientDashboard from '../module_client/dashboard/router/index.js'
import ClientCreateLastWill from '../module_client/activity/router/index.js'
import AnswerSurvey from '../modules/survey/router/index.js'
import ViewFuneralNotice from '../modules/funeral_notice/router/index.js'
import FuneralRequest from '../modules/funeral_request/router/index.js'
import FuneralDocuments from '../modules/funeral_documents/router/index.js'
import FuneralPayment from '../modules/funeral_payment/router/index.js'
import Unsubscribe from '../modules/unsubscribe/router/index.js'
import PrivacyPolicy from '../modules/privacy_policy/router/index.js'
import TermsConditions from '../modules/terms_conditions/router/index.js'
import ViewInvoice from '../modules/invoice/routes/index.js'
import NextSteps from '../modules/next_steps/routes/index.js'

/**
 * solicitor routes
 */
import SolicitorRoutes from '../module_solicitor/routes/index.js'


Vue.use(VueRouter)

export default new VueRouter({
    mode: 'history',
    routes: [
        ...Login,
        ...AdminDashboard,
        ...AdminFDComponent,
        ...AdminClientComponent,
        ...ClientDashboard,
        ...FuneralDirectorDashboard,
        ...FunderalDirectorClients,
        ...ClientCreateLastWill,
        ...FDSurveryRoute,
        ...Visitors,
        ...AdminFuneralNotice,
        ...FuneralDirectorFuneralNotice,
        ...FuneralDirectorSurvey,
        ...FuneralDirectorDocuments,
        ...FuneralDirectorEmails,
        ...AnswerSurvey,
        ...ViewFuneralNotice,
        ...FuneralRequest,
        ...AdminDocuments,
        ...AdminEmails,
        ...FuneralDocuments,
        ...Payments,
        ...FuneralPayment,
        ...Unsubscribe,
        ...PrivacyPolicy,
        ...TermsConditions,
        ...Solicitors,
        ...Charities,
        ...SolicitorRoutes,
        ...ViewInvoice,
        ...NextSteps,
    ]
})