import { render, staticRenderFns } from "./FuneralDirectorDashboard.vue?vue&type=template&id=6c306380&scoped=true&"
import script from "./FuneralDirectorDashboard.vue?vue&type=script&lang=js&"
export * from "./FuneralDirectorDashboard.vue?vue&type=script&lang=js&"
import style0 from "./FuneralDirectorDashboard.vue?vue&type=style&index=0&id=6c306380&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6c306380",
  null
  
)

export default component.exports